import React, { createContext, useState, useEffect, useContext } from "react";
import { useDatacontext } from "../../context";
import { dataURItoBlob } from "../../utils";
import { createCustomCollection, mintMultipleNfts, uploadFile, uploadFileMuchos } from "../../../unique/service";
import JSZip from "jszip";

const DataContext = createContext();
export const useMassMint = () => useContext(DataContext);

const MassMintProvider = (props) => {
    const { 
        data: { accounts, currentAccountIndex }, 
        fn: { setLoaderMessage } 
    } = useDatacontext();

    const [imagesZipContent, setImagesZipContent] = useState(null);
    const [csvContent, setCsvContent] = useState(null);
    const [imgFilename, setImgFilename] = useState("");
    const [csvFilename, setCsvFilename] = useState("");

    const [formErrors, setFormErrors] = useState(false);
    const [coverImage, setCoverImage] = useState(null);
    const [collectionAttrs, setCollectionAttrs] = useState([]);
    const [collectionData, setCollectionData] = useState({
        symbol: "",
        name: "",
        description: ""
    });

    useEffect(() => {
        const attrs = csvContent
            ? Object.keys(Object.assign({}, ...csvContent))
            : [];

        const attributes = attrs.filter(attr => attr !== "id").map(o => o.trim());
        setCollectionAttrs(attributes);
    }, [csvContent])

    // extract layers images from zip
    const getFilesImages = async (files, contents) => {
        let images = {};
        let len = files.length;
        let count = 0;

        const fn = async () => {
            const path = files[count];
            /* PENDING: CHECK IF FILES ARE IMAGES [png, jpeg, gif] */
            const b64String = await contents.file(path).async("base64");
            images[path] = `data:image/png;base64,${b64String}`;
            count++;

            if (count < len) {
                await fn();
            } else {
                return;
            }
        };

        await fn();
        return images;
    };

    const validateFormInfo = (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = "Required";
        }
        if (values.symbol.length > 4) {
            errors.symbol = "You write more than 4 chars";
        }
        if (!values.symbol) {
            errors.symbol = "Required";
        }

        return errors;
    };

    const getZipImagesBlob = async (zipImages) => {
        const filenames = csvContent.map((el, i) => `${i+1}.png`);
        const images = await getFilesImages(filenames, zipImages);

        const files = filenames.map((name) => {
            const [blob, mime] = dataURItoBlob(images[name]);
            return { name, content: blob }
        })

        const { fullUrl } = await uploadFileMuchos(files);
        return fullUrl
    }

    const mintCollection = async () => {
        const account = accounts[currentAccountIndex];

        if (imagesZipContent === null || csvContent === null) {
            alert("Please upload the required files!");
            return;
        }

        // check collection form values
        const errors = validateFormInfo(collectionData);
        if (Object.keys(errors).length !== 0) {
            alert("There's missing collection data in Settings form!");
            return;
        }

        // upload token images
        setLoaderMessage("uploading token images...")
        const nzip = new JSZip();
        const zipImages = await nzip.loadAsync(imagesZipContent);
        const fullUrl = await getZipImagesBlob(zipImages)
        if (!fullUrl) {
            alert("An error occurred uploading token images!");
            setLoaderMessage(null)
            return;
        }

        // create collection
        const urlTemplate = `${fullUrl}/{infix}`;
        const collectionId = await createCollection(account, urlTemplate);
        if (collectionId === null) {
            alert("An error occurred while creating a collection!");
            setLoaderMessage(null)
            return;
        }

        // mint tokens
        const tokens = await mintTokens(account, collectionId);
        if (collectionId === null) {
            alert("An error occurred while minting!");
            setLoaderMessage(null)
            return;
        }

        alert(`Success! The collection ${collectionId} was created and ${tokens.length} tokens were minted`)
        setTimeout(() => {
            window.location.replace('#/collections');
        }, 1000);

        setLoaderMessage(null)
    }

    const createCollection = async (account, urlTemplate) => {
        const { symbol, name, description } = collectionData;

        const customTokenPermissions = {
            mintMode: true,
            nesting: {
                tokenOwner: false,
                collectionAdmin: false,
            }
        }

        const attributes = collectionAttrs.map(attr => ({ 
            name: attr,
            type: "STRING",  // values => STRING, SELECT, MULTISELECT
            enums: [],
            required: true,  
            mutable: false,  
        }));

        setLoaderMessage("uploading cover image...")
        let ipfsCid = "Qme7wwEENK7mzBFGyQUvKVCfxxiDGyo19W2xt9bjUoo1pF"
        if (coverImage) {
            const [blob, mime] = dataURItoBlob(coverImage);
            const {cid} = await uploadFile(blob);
            ipfsCid = cid;
        }

        setLoaderMessage("creating collection...")
        const result = await createCustomCollection(account, {  
            name, 
            symbol, 
            description, 
            attributes, 
            coverIpfs: ipfsCid, 
            customTokenPermissions, 
            urlTemplate 
        });

        return result;
    }

    const mintTokens = async (account, collectionId) => {
        const data = csvContent.map((token, index) => {
            const tokenId = index + 1;
            const encodedAttributes = {};
            Object.keys(collectionAttrs).forEach((attr, i) => {
                const attrName = collectionAttrs[attr];
                encodedAttributes[i] = {_: token[attrName]}
            })

            const image = { urlInfix: `${tokenId}.png`}

            return { data: { encodedAttributes, image } }
        })

        setLoaderMessage(`minting ${data.length} tokens`);
        const result = await mintMultipleNfts(account, collectionId, data);

        return result;
    }

    const data = {
        csvContent,
        imagesZipContent,
        formErrors,
        coverImage,
        collectionData,
        imgFilename,
        csvFilename
    };

    const fn = {
        setFormErrors,
        setCoverImage,
        setCollectionData,
        setImagesZipContent,
        setCsvContent,
        validateFormInfo,
        mintCollection,
        setImgFilename,
        setCsvFilename
    };

    return (
        <DataContext.Provider value={{ data, fn }}>
            {props.children}
        </DataContext.Provider>
    );
};

export default MassMintProvider;
