import { useEffect, useState } from "react";
import { useDatacontext } from "../app/context";
import ModalSynth from "../app/synthetiser/ModalSynth";
import Tokens from "../app/synthetiser/Tokens";
import Layout from "../app/layout";
import Button from "../app/common/Button";
import { checkAllowList, mintNewTokenSvg, sendAirdrop } from "../unique/service";

const dynamicCollectionId = 1963;
const baseImage = "bafybeibbjoifig7hvx4krbrmomasfwur7zzgqhheeyz25xth4wjx5cshye";

function Synthetiser() {
    const [currentTokenId, setCurrentTokenId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [account, setAccount] = useState({});
    const {
        data: { accounts, balance, currentAccountIndex },
        fn: { setLoaderMessage },
    } = useDatacontext();

    useEffect(() => {
        setAccount({});
        setTimeout(() => {
            const account = accounts[currentAccountIndex] || {};
            setAccount(account);
        }, 100);
    }, [accounts, currentAccountIndex]);

    const openLabModal = (tokenId) => {
        setCurrentTokenId(tokenId);
        setShowModal(true);
    };

    const handleMintToken = async () => {
        // send airdrop
        setLoaderMessage("sending onboard airdrop...")
        if (parseInt(balance.amount) < 10) {
            const airdrop = await sendAirdrop(account.address);
        } else {
            console.log("no airdrop needed!");
        }

        const allow = await checkAllowList(account, dynamicCollectionId);

        setLoaderMessage("Minting Dynamic Token...");

        const result = await mintNewTokenSvg(account, dynamicCollectionId);

        if (result === null) alert("An error ocurred while Minting!")

        setLoaderMessage(null);
    }

    const tokens = account.address ? (
        <Tokens
            owner={account.address}
            collectionIds={[dynamicCollectionId]}
            openModal={openLabModal}
        />
    ) : null;

    return (
        <Layout>
            <div className="flex items-center justify-between mt-4 mb-2">
                <h3 className="text-gray-100 font-semibold text-xl">
                    <span>Dynamic NFT's Synthetiser</span>{" "}
                    <small className="text-yellow-600 text-md">
                        ({account.addressShort})
                    </small>
                </h3>
                <Button onClick={() => handleMintToken()}>
                    Mint Test Token
                </Button>
            </div>

            {tokens}

            <ModalSynth
                tokenId={currentTokenId}
                collectionId={dynamicCollectionId}
                isShow={showModal}
                setIsShow={setShowModal}
            />
        </Layout>
    );
}

export default Synthetiser;
