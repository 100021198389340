import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { ImageIcon } from "../icons";
import { useDatacontext } from "../context";
import { getCollectionById, uploadFile, mintCustomNft } from "../../unique/service";
import { dataURItoBlob, formFieldStyle, strokeButtonStyle } from "../utils";

function NewTokenForm() {
    const [ collection, setCollection ] = useState({});
    const [ attributes, setAttributes ] = useState([]);
    const [ image, setImage ] = useState(null);
    const [ imageError, setImageError ] = useState("");
    const { collectionId } = useParams(); 

    const {
        data: { accounts, currentAccountIndex },
        fn: { setLoaderMessage }
    } = useDatacontext()

    const account = accounts[currentAccountIndex];

    useEffect(() => {
        getCollection()
    }, [collectionId])

    const getCollection = async () => {
        const collection = await getCollectionById(account, collectionId);
        setCollection(collection);
        const _attributes = Object.keys(collection.schema.attributesSchema).map(k => collection.schema.attributesSchema[k])
        setAttributes(_attributes);
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        if (!checkValidate()) return;

        const account = accounts[currentAccountIndex];

        const _attributes = {};
        const elements = evt.target.querySelectorAll('select, input:not([type="file"])');
        elements.forEach((el, index) => {
            if (el.multiple) {
                _attributes[index] = Object.keys(el.options).filter(i => el.options[i].selected).map(o => parseInt(o));
            }
            else if (el.type === "select-one") {
                _attributes[index] = parseInt(el.value);
            }
            else {
                _attributes[index] = {_: el.value};
            }

            return ;
        });
        // console.log(_attributes)
        // return;

        setLoaderMessage("uploading image...")
        let ipfsCid = "Qme7wwEENK7mzBFGyQUvKVCfxxiDGyo19W2xt9bjUoo1pF"
        if (image) {
            const [blob, mime] = dataURItoBlob(image); 
            const {cid} = await uploadFile(blob);
            ipfsCid = cid;
        }

        setLoaderMessage("minting nft...")
        const result = await mintCustomNft(account, collectionId, { ipfsCid }, _attributes);

        if (result !== null) {
            alert(`Token ${result} created sucessfully!`)
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
        else {
            alert("An error occurred!")
        }

        setLoaderMessage(null)
    }

    const checkValidate = (values) => {
        const errors = {};
        let isValidated = true;

        if (image === null) {
            const err = "You should add an image to upload";
            errors.image = err;
            setImageError(err);
            isValidated = false
        }
        else {
            setImageError("")
        }

        return isValidated;
    };

    const handleImageChange = (image) => {
        const reader = new FileReader();
        reader.onload = function () {
            setImage(reader.result);
        };
        reader.readAsDataURL(image);
    };

    const Error = ({ children }) => (
        <div className="text-xs text-red-400 py-1 px-2">{children}</div>
    );

    const renderField = (attr) => {
        if (attr.isArray) { 
            const _enumValues = Object.keys(attr.enumValues).map(k => attr.enumValues[k]._)
            return (
                <>
                    <label className="block font-semibold capitalize">{attr.name._}</label>
                    <small className="block text-xs text-gray-400 mb-2">Choose multiple options pressing Ctrl</small>
                    <div className="multiselect multiselect--multiple">
                        <select
                            multiple={true}
                            name={attr.name._}
                            required={!attr.optional}
                            className={formFieldStyle}
                        >
                            {
                                _enumValues.map((text, index) => (
                                    <option key={text} value={index}>
                                        {text}
                                    </option>
                                ))
                            }
                        </select>
                        <span className="focus"></span>
                    </div>
                </>
            )
        }
        else if (attr.hasOwnProperty("enumValues")) {
            const _enumValues = Object.keys(attr.enumValues).map(k => attr.enumValues[k]._)
            return (
                <>
                    <label className="block font-semibold capitalize -mb-1">{attr.name._}</label>
                    <select
                        name={attr.name._}
                        required={!attr.optional}
                        className={formFieldStyle}
                    >
                        {
                            _enumValues.map((text, index) => (
                                <option key={text} value={index}>
                                    {text}
                                </option>
                            ))
                        }
                    </select>
                </>
            )
        }
        else {
            return (
                <>
                    <label className="block font-semibold capitalize -mb-1">{attr.name._}</label>
                    <input
                        className={formFieldStyle}
                        required={!attr.optional}
                        name={attr.name._}
                        type="text"
                    />
                </>
            )
        }
    }

    const ImageInput = () => (
        <FileUploader
            value={image}
            handleChange={handleImageChange}
            name="image"
            multiple={false}
            types={["JPG", "JPEG", "PNG", "GIF"]}
        >
            <div className="cursor-pointer mt-1">
                {image ? (
                    <div className="img-prev" style={{backgroundImage: `url(${image})`}}></div>
                ) : (
                    <div className="upload-field py-10 flex flex-col items-center justify-center text-center rounded-md">
                        <ImageIcon />
                        <p className="text-sm font-semibold text-gray-300">
                            Upload or drop an img right here
                        </p>
                    </div>
                )}
            </div>
        </FileUploader>
    )

    return (
        <>
            <h4 className="text-lg mb-4 font-semibold">Mint Token</h4>
            <h4 className="text-sm text-gray-300">Mint an NFT for the collection <span className="font-bold">{collection.name}</span></h4>
            <div className="w-full p-0 my-2">
                    <form onSubmit={handleSubmit}> 
                        <div className="mb-4">
                            <div className="flex">
                                <div className="w-2/3">
                                    {
                                        attributes.map(attr => <div className="mb-2" key={attr.name._}>{renderField(attr)}</div>)
                                    }
                                    <Error>{imageError}</Error>
                                </div>
                                <div className="w-1/3 h-32 p-2">
                                    <ImageInput />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end items-center">
                            <button type="submit" className={strokeButtonStyle}>
                                <span className="text-md block py-1">
                                    Mint
                                </span>
                            </button>
                        </div>
                    </form>
            </div>

        </>
    );
}

export default NewTokenForm;