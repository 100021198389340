import _ from "lodash";
import { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { TagsInput } from "react-tag-input-component";
import { FileUploader } from "react-drag-drop-files";
import { dataURItoBlob, existOrFalse, formFieldStyle, strokeButtonStyle } from "../utils";
import { ImageIcon } from "../icons";
import { useDatacontext } from "../context";
import { createCustomCollection, uploadFile } from "../../unique/service";
import AttributesTable from "./AttributesTable";
import Layout from "../layout";

const NewCollectionForm = () => {
    const [types, setTypes] = useState(["root"]);
    const [image, setImage] = useState(null);
    const [attrs, setAttrs] = useState([]);

    const {
        data: { accounts, currentAccountIndex },
        fn: { setLoaderMessage }
    } = useDatacontext()

    const handleImageChange = (image) => {
        const reader = new FileReader();
        reader.onload = function () {
            setImage(reader.result);
        };
        reader.readAsDataURL(image);
    };

    const handleSubmit = async (values) => {
        const account = accounts[currentAccountIndex];
        const { symbol, name, description } = values;

        // format attributes data
        const attributes = attrs.map(id => ({ 
            name: values[`name_${id}`],
            type: values[`type_${id}`] || "STRING",  
            enums: values[`values_${id}`] ? values[`values_${id}`].split(",") : "",   // fix this!!! 
            required: existOrFalse(values[`required_${id}`]),  
            mutable: existOrFalse(values[`mutable_${id}`]),  
        }))

        const customTokenPermissions = {
            mintMode: existOrFalse(values['mint_mode']),
            nesting: {
                tokenOwner: existOrFalse(values['nesting_owner']),
                collectionAdmin: existOrFalse(values['nesting_admin']) ,
            }
        }

        setLoaderMessage("uploading image...")
        let ipfsCid = "Qme7wwEENK7mzBFGyQUvKVCfxxiDGyo19W2xt9bjUoo1pF"
        if (image) {
            const [blob, mime] = dataURItoBlob(image); 
            const {cid} = await uploadFile(blob);
            ipfsCid = cid;
        }

        setLoaderMessage("creating collection...")
        const data = { coverIpfs: ipfsCid, name, description, symbol, attributes, customTokenPermissions };
        const result = await createCustomCollection(account, data);

        if (result !== null) {
            alert(`Collection ${result} created sucessfully!`)
            setTimeout(() => {
                //window.location.reload();
                window.location.replace('#/collections');
            }, 1000);
        }
        else {
            alert("An error occurred!")
        }

        setLoaderMessage(null)
    };

    const handleValidate = (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = "Required";
        }
        if (values.symbol.length > 4) {
            errors.symbol = "You write more than 4 chars";
        }
        if (!values.symbol) {
            errors.symbol = "Required";
        }

        return errors;
    };

    const Error = ({ children }) => (
        <div className="text-xs text-red-400 py-1 px-2">{children}</div>
    );

    const ImageInput = () => (
        <FileUploader
            value={image}
            handleChange={handleImageChange}
            name="image"
            multiple={false}
            types={["JPG", "JPEG", "PNG", "GIF"]}
        >
            <div className="cursor-pointer mt-1">
                {image ? (
                    <div className="img-prev" style={{backgroundImage: `url(${image})`, height: "210px"}}></div>
                ) : (
                    <div 
                        className="upload-field py-5 px-2 flex flex-col items-center justify-center text-center rounded-md"
                        style={{height: "210px"}}
                    >
                        <ImageIcon />
                        <p className="text-sm font-semibold text-gray-300">
                            Upload or drop a cover image here
                        </p>
                    </div>
                )}
            </div>
        </FileUploader>
    )

    return (
        <Layout>
            <h3 className="text-gray-100 font-bold text-xl mt-4 mb-4">New Collection</h3>
            <h5 className="text-sm text-gray-300">Generates a new collection with an advanced schema</h5>
            <div className="w-full text-gray-100 p-0 my-2">
                <Formik
                    onSubmit={handleSubmit}
                    validate={handleValidate}
                    initialValues={{ symbol: "", name: "", description: "" }}
                >
                    <Form>
                        <div className="mb-4">
                            <div className="flex">
                                <div className="w-2/3">
                                    <Field
                                        className={`${formFieldStyle} mb-2`}
                                        name="symbol"
                                        type="text"
                                        placeholder="Symbol (Max: 4 chars)"
                                    />
                                    <ErrorMessage
                                        name="symbol"
                                        render={(msg) => <Error>{msg}</Error>}
                                    />
                                    <Field
                                        className={formFieldStyle}
                                        name="name"
                                        type="text"
                                        placeholder="Name  (Max: 64 chars)"
                                    />
                                    <ErrorMessage
                                        name="name"
                                        render={(msg) => <Error>{msg}</Error>}
                                    />
                                    <Field
                                        className={formFieldStyle}
                                        name="description"
                                        as="textarea"
                                        rows={3}
                                        placeholder="Description  (Max: 256 chars)"
                                    />
                                </div>
                                <div className="w-1/3 h-32 p-2">
                                    <ImageInput />
                                </div>
                            </div>
                            
                            <div className="mt-2">
                                <div>
                                    <span className="font-semibold mr-2">Mint Mode:</span> 
                                    Active <Field type="checkbox" name="mint_mode" />
                                </div>
                                <div>
                                    <span className="font-semibold mr-2">Nesting Permissions:</span> 
                                    <span>Collection Admin</span> <Field type="checkbox" name="nesting_admin" />
                                    <span className="ml-3"></span>
                                    <span>Token Owner</span> <Field type="checkbox" name="nesting_owner" />
                                </div>
                            </div>
                            <div className="mt-2">
                                {/* <h4 className="mb-6 font-semibold">Attributes</h4> */}
                                <AttributesTable attrs={attrs} setAttrs={setAttrs} />
                            </div>
                        </div>
                        <div className="flex justify-end items-center">
                            <button type="submit" className={strokeButtonStyle}>
                                <span className="text-md block py-1">
                                    Generate
                                </span>
                            </button>
                        </div>
                    </Form>
                </Formik>
            </div>
        </Layout>
    );
}

export default NewCollectionForm;