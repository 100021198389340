import { useDatacontext } from "../app/context";
import Layout from "../app/layout";
import EditorContainer from "../app/editor/EditorContainer";
import { useEffect } from "react";


function BundleEditor() {
    const {
        data: { accounts, currentAccountIndex },
        fn: { setLoaderMessage },
    } = useDatacontext();

    useEffect(() => {
        const refreshed = window.sessionStorage.getItem('refreshed')
        if (refreshed === null) {
            window.sessionStorage.setItem('refreshed', 1);
            window.location.reload();
        }
    }, [])

    const editorHeight = "500px";

    return (
        <Layout>
            <h2 className="text-gray-100 font-semibold text-xl my-4">
                Bundle Editor
            </h2>
            <div
                className="w-full shadow-md bg-darkdeep rounded my-4"
                style={{ height: editorHeight }}
            >
                {accounts.length > 0 ? (
                    <EditorContainer
                        account={accounts[currentAccountIndex]}
                        setLoaderMessage={setLoaderMessage}
                    />
                ) : (
                    <div></div>
                )}
            </div>
        </Layout>
    );
}

export default BundleEditor;
