import React, { createContext, useState, useEffect, useContext } from "react";
import JSZip from "jszip";
import { generateRandomExample } from "./generator";
import { useDatacontext } from "../../context";
import { dataURItoBlob } from "../../utils";
import { createCustomCollection, mintMultipleNfts, uploadFile, uploadFileMuchos, uploadZip } from "../../../unique/service";

const DataContext = createContext();
export const useGenerativeMint = () => useContext(DataContext);

const GenerativeMintProvider = (props) => {
    const { 
        data: { accounts, currentAccountIndex }, 
        fn: { setLoaderMessage } 
    } = useDatacontext();

    const [file, setFile] = useState(null);
    const [filename, setFilename] = useState("");
    const [layersContent, setLayersContent] = useState([]);
    const [layersExample, setLayersExample] = useState([]);
    const [layersImages, setLayersImages] = useState([]);
    const [maxCombinations, setMaxCombinations] = useState(0);
    const [simulatedResult, setSimulatedResult] = useState([]);
    
    const [formErrors, setFormErrors] = useState(false);
    const [coverImage, setCoverImage] = useState(null);
    const [collectionData, setCollectionData] = useState({
        tokensQty: "",
        symbol: "",
        name: "",
        description: "",
    });

    const reorderLayers = (_layersContent, _layersExample) => {
        setLayersContent(_layersContent);
        setLayersExample(_layersExample);
    };

    const handleFileChange = (input) => {
        const reader = new FileReader();
        reader.onload = function () {
            setFile(reader.result);
        };

        setFilename(input.name);
        reader.readAsArrayBuffer(input);
    };

    const handleReadZip = async () => {
        console.log("reading zip...");
        const nzip = new JSZip();
        const contents = await nzip.loadAsync(file);

        // a bit of magic
        const dirList = contents.files;
        const keys = Object.keys(dirList);

        const layers = keys.filter((k) => dirList[k].dir);
        const files = keys.filter((k) => !dirList[k].dir);

        const _layersContent = layers.map((layer, i) => ({
            name: layer.split("/")[1],
            probability: 1.0,
            options: files
                .filter((k) => k.indexOf(layer) !== -1)
                .map((fileroute) => ({
                    name: fileroute.split("/")[2],
                    file: fileroute,
                    weight: 1,
                })),
        }));
        setLayersContent(_layersContent);

        const images = await getFilesImages(files, contents);
        setLayersImages(images);

        const { selectedTraits:example } = generateRandomExample(_layersContent);
        const randomExample = Object.keys(example).map(layer => images[example[layer]["f"]]);
        setLayersExample(randomExample);

        const _maxCombinations = _layersContent
            .map((o) => o.options.length)
            .reduce((a, b) => a * b, 1);
        setMaxCombinations(_maxCombinations);

        // reset simulated result
        setSimulatedResult([]);
    };

    // extract layers images from zip
    const getFilesImages = async (files, contents) => {
        let images = {};
        let len = files.length;
        let count = 0;

        const fn = async () => {
            const path = files[count];
            /* PENDING: CHECK IF FILES ARE IMAGES [png, jpeg, gif] */
            const b64String = await contents.file(path).async("base64");
            images[path] = `data:image/png;base64,${b64String}`;
            count++;

            if (count < len) {
                await fn();
            } else {
                return;
            }
        };

        await fn();
        return images;
    };

    const handleEditName = async (text, layer, path) => {
        //console.log("NEW NAME", text, layer, path)
        const content = [...layersContent];
        const layerIndex = content.findIndex((l) => l.name === layer);
        const optionIndex = content[layerIndex]["options"].findIndex(
            (o) => o.file === path
        );
        content[layerIndex]["options"][optionIndex]["name"] = text;
        setLayersContent(content);
    };

    const handleEditWeight = async (val, layer, path) => {
        //console.log("NEW WEIGHT", val, layer, path);
        const content = [...layersContent];
        const layerIndex = content.findIndex((l) => l.name === layer);
        const optionIndex = content[layerIndex]["options"].findIndex(
            (o) => o.file === path
        );
        content[layerIndex]["options"][optionIndex]["weight"] = val;
        setLayersContent(content);
    };

    const validateFormInfo = (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = "Required";
        }
        if (!values.tokensQty) {
            errors.tokensQty = "Required";
        }
        if (values.tokensQty > maxCombinations) {
            errors.tokensQty = `The MAX number of possible combinations is ${maxCombinations}`;
        }
        if (values.symbol.length > 4) {
            errors.symbol = "You write more than 4 chars";
        }
        if (!values.symbol) {
            errors.symbol = "Required";
        }

        return errors;
    }

    const getZipImagesBlob = async (zipImages) => {
        const filenames = simulatedResult.map((el, i) => `${i+1}.png`);
        const images = await getFilesImages(filenames, zipImages);

        const files = filenames.map((name) => {
            const [blob, mime] = dataURItoBlob(images[name]);
            return { name, content: blob }
        })

        const { fullUrl } = await uploadFileMuchos(files);
        return fullUrl
    }

    const mintCollection = async (zipImages) => {
        const account = accounts[currentAccountIndex];

        // check collection form values
        const errors = validateFormInfo(collectionData);
        if (Object.keys(errors).length !== 0) {
            alert("There's missing collection data in Settings form!");
            return;
        }

        // upload token images
        setLoaderMessage("uploading token images...")
        //const blob = await zipImages.generateAsync({ type:"blob" });
        //const {fullUrl} = await uploadZip(account, blob);
        const fullUrl = await getZipImagesBlob(zipImages)
        if (!fullUrl) {
            alert("An error occurred uploading token images!");
            setLoaderMessage(null)
            return;
        }

        // create collection
        const urlTemplate = `${fullUrl}/{infix}`;
        const collectionId = await createCollection(account, urlTemplate);
        if (collectionId === null) {
            alert("An error occurred while creating a collection!");
            setLoaderMessage(null)
            return;
        }

        // mint tokens
        const tokens = await mintTokens(account, collectionId);
        if (collectionId === null) {
            alert("An error occurred while minting!");
            setLoaderMessage(null)
            return;
        }

        alert(`Success! The collection ${collectionId} was created and ${tokens.length} tokens were minted`)
        setTimeout(() => {
            window.location.replace('#/collections');
        }, 1000);

        setLoaderMessage(null)
    }

    const createCollection = async (account, urlTemplate) => {
        const { symbol, name, description } = collectionData;

        const customTokenPermissions = {
            mintMode: true,
            nesting: {
                tokenOwner: false,
                collectionAdmin: false,
            }
        }

        const attributes = layersContent.map(layer => ({ 
            name: layer.name,
            type: "SELECT",  // values => STRING, SELECT, MULTISELECT
            //enums: layer.options.map(o => o.name),
            enums: layer.options.map(o => o.name.replace(".png", "")),
            required: true,  
            mutable: false,  
        }));

        setLoaderMessage("uploading cover image...")
        let ipfsCid = "Qme7wwEENK7mzBFGyQUvKVCfxxiDGyo19W2xt9bjUoo1pF"
        if (coverImage) {
            const [blob, mime] = dataURItoBlob(coverImage);
            const {cid} = await uploadFile(blob);
            ipfsCid = cid;
        }

        setLoaderMessage("creating collection...")
        const result = await createCustomCollection(account, {  
            name, 
            symbol, 
            description, 
            attributes, 
            coverIpfs: ipfsCid, 
            customTokenPermissions, 
            urlTemplate 
        });

        return result;
    }

    const mintTokens = async (account, collectionId) => {
        const data = simulatedResult.map((token, index) => {
            const tokenId = index + 1;
            const encodedAttributes = {};
            Object.keys(token).forEach((layer, i) => {
                const attrIndex = layersContent[i].options.findIndex(o => o.name === token[layer].n)
                encodedAttributes[i] = attrIndex
            })

            const image = { urlInfix: `${tokenId}.png`}

            return { data: { encodedAttributes, image } }
        })

        setLoaderMessage(`minting ${data.length} tokens`);
        const result = await mintMultipleNfts(account, collectionId, data);

        return result;
    }

    const data = {
        file,
        filename,
        layersImages,
        layersContent,
        layersExample,
        maxCombinations,
        simulatedResult,
        collectionData,
        formErrors,
        coverImage
    };

    const fn = {
        reorderLayers,
        handleFileChange,
        handleReadZip,
        handleEditName,
        handleEditWeight,
        setCollectionData,
        setSimulatedResult,
        validateFormInfo,
        setFormErrors,
        setCoverImage,
        mintCollection
    };

    return (
        <DataContext.Provider value={{ data, fn }}>
            {props.children}
        </DataContext.Provider>
    );
};

export default GenerativeMintProvider;
