import { useEffect, useState } from "react";
import _ from "lodash";
import DOMPurify from "dompurify";
import { useDatacontext } from "../context";
import { getTokenDetailInfo, setNftProperties, uploadFileSVG } from "../../unique/service";
import { fallbackNoImage } from "../utils";
import Button from "../common/Button";
import SourceMutators from "./SourceMutators";
import { generator } from "./generator";
import { baseNewspaper, heroImage, mutations, sources } from "./sources";


const SourcesLab = ({ collectionId, tokenId }) => {
    const [ currentCity, setCurrentCity ] = useState("ny");
    const [ currentToken, setCurrentToken ] = useState({});
    const [ imageSource, setImageSource ] = useState(fallbackNoImage);
    const [ prevPanelData, setPrevPanelData] = useState([]);
    const [ newspaperData, setNewspaperData ] = useState(baseNewspaper);

    const {
        data: { accounts, currentAccountIndex },
        fn: { setLoaderMessage }
    } = useDatacontext()

    useEffect(() => {
        if (tokenId !== currentToken.tokenId)
            updateData();  
    }, [tokenId]);

    const updateData = async () => {
        const account = accounts[currentAccountIndex];
        if (!account) return;

        const token = await getTokenDetailInfo(account, collectionId, tokenId);
        setCurrentToken(token.tokenDetail)
        setImageSource(token.tokenDetail.image.fullUrl)
        console.log(token)
    };

    const handleChangeMutator = async (panels, data) => {
        const currentPanelData = data.filter((o,i) => i !== 0).map((o, i) => o.length > 0 ? `${o[0].id}|${panels[i+1]}` : null);
        const filter = _.difference(currentPanelData, prevPanelData);
        //console.log(filter, [...currentPanelData, ...prevPanelData])

        if(!filter[0]) return;

        const [type, action] = filter[0].split("|");
        let response;

        if (type === "news") {
            //const request = await fetch(sources.news[currentCity]);
            const headers = new Headers()
            headers.append("Content-Type", "application/json")

            const body = { "url": sources.news[currentCity] }
            const options = {
                method: "POST",
                headers,
                mode: "cors",
                body: JSON.stringify(body),
            }

            const request = await fetch("https://eo9jezcksz4sg2e.m.pipedream.net", options)
            const resp = await request.json();
            response = resp.data;
        } else if (type === "weather") {
            const request = await fetch(sources.weather[currentCity]);
            response = await request.json();
        } else { // btc, eth, dot
            const request = await fetch(sources.prices);
            const r = await request.json();
            response = type === "btc" ? r.markets["BTC-USD"] : type === "eth" ? r.markets["ETH-USD"] : r.markets["DOT-USD"];
        }
        //console.log(type,response)

        let result = {};
        if (action === "background") {
            if (type === "news") {
                result.background = mutations.background.news;
            } else if (type === "weather") {
                const v = mutations.background.weather;
                result.background = (response.weather[0].description.indexOf('rain') !== -1) ? v.rain : v.clear;
            } else { // btc, eth, dot
                const v = mutations.background.price;
                result.background = (response.priceChange24H > -1) ? v.bull : v.bear;
            }   
        }

        if (action === "header") {
            if (type === "news") {
                result.headerTitle = response.articles[0].title;
            } else if (type === "weather") {
                const r = response;
                result.headerTitle = mutations.header.weather(currentCity, r.weather[0].description, r.main.temp)
            } else { // btc, eth, dot
                const r = response;
                result.headerTitle = mutations.header.price(r.baseAsset, r.oraclePrice, r.priceChange24H);
            }  
        }

        if (action === "leftCol") {
            if (type === "news") {
                result.avatarTitle = mutations.leftcol.top.news;
                result.avatarSubtitle = mutations.leftcol.down.news;
                result.avatarImage = mutations.leftcol.image.news;
            } else if (type === "weather") {
                result.avatarTitle = response.weather[0].description;
                result.avatarSubtitle = mutations.leftcol.down.weather;
                result.avatarImage = mutations.leftcol.image.weather(response.weather[0].main.toLowerCase());
            } else { // btc, eth, dot
                const trend = (response.priceChange24H > -1)? "bull" : "bear";
                result.avatarTitle = mutations.leftcol.top.price[trend](response.baseAsset);
                result.avatarSubtitle = mutations.leftcol.down.price[trend];
                result.avatarImage = mutations.leftcol.image.price[trend];
            }  
        }

        if (action === "rightCol") {
            if (type === "news") {
                result.weatherIcon = mutations.rightcol.icon.news
                result.weatherTemp = "LMK" //WTF
                result.weatherStatus = mutations.rightcol.status.news
            } else if (type === "weather") {
                result.weatherIcon = mutations.rightcol.icon.weather(response.weather[0].main.toLowerCase())
                result.weatherTemp = parseInt(response.main.temp)+"°"
                result.weatherStatus = response.weather[0].description
            } else { // btc, eth, dot
                const trend = (response.priceChange24H > -1)? "bull" : "bear";
                result.weatherIcon = mutations.rightcol.icon.price[trend]
                result.weatherTemp = response.baseAsset
                result.weatherStatus = mutations.rightcol.status.price[trend]
            }  
        }
        console.log(result)
        result.weatherTitle = `Weather in ${currentCity}`;
        result.headerImage = heroImage[currentCity];
        
        setPrevPanelData(currentPanelData);
        handleGenerateSVG(result)
    }

    const handleGenerateSVG = async (newData) => {
        if (newData.background) {
            const svg = await fetch(newData.background);
            newData.background = await svg.text();
        }   

        if (newData.headerImage) {
            const image = await fetch(newData.headerImage);
            newData.headerImage = await image.text();
        }  

        if (newData.weatherIcon) {
            const weather = await fetch(newData.weatherIcon);
            newData.weatherIcon = await weather.text();
        }

        if (newData.avatarImage) {
            const avatar = await fetch(newData.avatarImage);
            newData.avatarImage = await avatar.text();
        }
        
        const data = {...newspaperData, ...newData}; 
        const newspaper = generator(data);
        //console.log(data, newspaper)

        const result = data.background.replace("<!--#####-->", newspaper);
        //console.log(result)
        setImageSource(result)
        setNewspaperData(data)
    }

    const handleChangeCity = (city) => {
        setCurrentCity(city)
        handleGenerateSVG({ headerImage: heroImage[city] })
    }

    const sanitizedData = (data) => ({
        //__html: DOMPurify.sanitize(data)
        __html: data
    })

    const updateNFT = async () => {
        const panelData = btoa(JSON.stringify(prevPanelData));
        const account = accounts[currentAccountIndex];

        setLoaderMessage("uploading image...")
        const ipfsCid = await uploadFileSVG(imageSource);

        console.log('Updating properties...', tokenId)
        setLoaderMessage("updating properties...") 
        const result = await setNftProperties(
            account,
            collectionId,
            tokenId,
            [
                //{ key: "a.0", value: `{"_":"${panelData}"}` },
                { key: "i.c", value: ipfsCid }
            ]
        )
        console.log(result)
        setLoaderMessage(null)   
    }
    

    const nftTitle = currentToken.collection ? `${currentToken.collection.tokenPrefix} #${tokenId}` : "";

    return (
        <>
            <h4 className="text-sm text-gray-300">
                Play with the image synthetiser by combining Sources and
                Mutators
            </h4>

            <div className="my-2 px-2 w-full h-10 bg-darkdeep font-sans flex justify-between items-center">
                <div>
                    <span className="items-center my-1 text-gray-100 font-bold p-2">
                        {nftTitle}
                    </span>
                    <button onClick={() => updateNFT()} className="ml-2 bg-blue-400 text-white px-4 py-1 text-sm rounded-md">Update NFT</button>
                </div>
                <div>
                    <Button selected={currentCity == "lisbon"} onClick={() => handleChangeCity("lisbon")}>Lisbon</Button>
                    <Button selected={currentCity == "london"} onClick={() => handleChangeCity("london")}>London</Button>
                    <Button selected={currentCity == "paris"} onClick={() => handleChangeCity("paris")}>Paris</Button>
                    <Button selected={currentCity == "ny"} onClick={() => handleChangeCity("ny")}>New York</Button>
                </div>
            </div>

            <div className="h-96 flex flex-col sm:flex-row font-sans">
                <div className="w-full lg:w-2/4 p-3">
                    <SourceMutators onChange={handleChangeMutator}/>
                </div>
                <div className="w-full lg:w-2/4 p-3">
                    {
                        imageSource.startsWith("http") ? (
                            <img src={imageSource} className="w-full h-auto" alt="nft" />
                        ) : (
                            <div className="dynamic-svg-wrap" 
                                dangerouslySetInnerHTML={sanitizedData(imageSource)} 
                            />
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default SourcesLab;
