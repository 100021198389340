import { useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDatacontext } from "../app/context";
import Layout from "../app/layout";
import Tokens from "../app/common/Tokens";
import Collections from "../app/common/Collections";
import ModalForm from "../app/collections/ModalForm";
import NewTokenForm from "../app/collections/NewTokenForm";
import {
    CaretRightIcon,
    GenerativeIcon,
    MassMintIcon,
    TagIcon,
} from "../app/icons";
import { strokeButtonStyle } from "../app/utils";

function CollectionsPage() {
    const {
        data: { accounts, currentAccountIndex },
    } = useDatacontext();

    const { collectionId } = useParams();

    const account = accounts[currentAccountIndex];
    const owner = account ? account.address : "";

    if (!account) return null;

    return (
        <Layout>
            {collectionId ? (
                <>
                    <div className="flex justify-between items-center">
                        <h3 className="text-gray-100 font-bold text-xl mt-4 flex items-center">
                            <span>Your Collections</span>
                            <CaretRightIcon />
                            <span>{collectionId}</span>
                        </h3>
                        <ModalForm buttonTitle="Mint Token">
                            <NewTokenForm />
                        </ModalForm>
                    </div>

                    <Tokens
                        owner={owner}
                        collectionIds={parseInt(collectionId)}
                    />
                </>
            ) : (
                <>
                    <div className="flex justify-between items-center">
                        <h3 className="text-white font-bold text-xl mt-4">
                            <span>Your Collections</span>
                        </h3>
                        {/* <Link to="/collections/new">
                            <button className={strokeButtonStyle}>New Collection</button>
                        </Link> */}
                        <ModalForm buttonTitle="New Collection">
                            <div className="p-3 text-gray-100">
                                <h3 className="font-semibold text-xl mb-3">
                                    What do you want to do?
                                </h3>
                                <div className="mb-2 flex items-center">
                                    <TagIcon />{" "}
                                    <Link to="/collections/new">
                                        Just to create a Collection
                                    </Link>
                                </div>
                                <div className="mb-2 flex items-center">
                                    <GenerativeIcon />{" "}
                                    <Link to="/collections/generative">
                                        Mint a generative NFT collection
                                    </Link>
                                </div>
                                <div className="mb-2 flex items-center">
                                    <MassMintIcon />{" "}
                                    <Link to="/collections/massmint">
                                        Mint tokens from a set of
                                        already made Images (mass mint)
                                    </Link>
                                </div>
                            </div>
                        </ModalForm>
                    </div>

                    <Collections owner={owner} />
                </>
            )}
        </Layout>
    );
}

export default CollectionsPage;
