import JSZip from "jszip";
import mergeImages from "merge-images";
import { saveAs } from "file-saver";
import { MersenneTwister19937, bool, real } from "./random";

// include batch mint here for randomExample and FinalMint ****

export function generateRandomExample(layers) {
    let selection = randomlySelectLayers(layers);
    return selection;
}

export function generateNFTs(num, layers) {
    let simulatedMint = [];
    let generated = new Set();

    const maxCombinations = layers.map(o => o.options.length).reduce((a,b) => a*b, 1);
    console.log("Max layers combinations", maxCombinations)

    for (let tokenId=0; tokenId<num; tokenId++) {
        //console.log(`Generating NFT #${tokenId} …`)
        let selection = randomlySelectLayers(layers)
        const traitsStr = JSON.stringify(selection.selectedTraits)

        if (generated.has(traitsStr)) {
            //console.log("Duplicate detected. Retry …")
            tokenId--
            continue
        } else {
            generated.add(traitsStr)
            simulatedMint.push(selection.selectedTraits)
            //console.log("Generated tokenId ", tokenId)
        }
    }

    console.log('finish! generated tokens: ' + num)
    return simulatedMint;
}

export async function generateNFTImages(simulatedMint, layerImages) {
    let zipResult = new JSZip();
    let len = simulatedMint.length;
    let tokenId = 0;

    const fn = async () => {
        const token = simulatedMint[tokenId];
        const images = Object.keys(token).map(layer => layerImages[token[layer]["f"]]);
        const arrayCopy = [...images];
        arrayCopy.reverse(); // reverse array for the right layer order

        const imageB64 = await mergeImages(arrayCopy)
        const fileIndex = tokenId + 1;
        zipResult.file(`${fileIndex}.png`, imageB64.replace("data:image/png;base64,", ""), { base64: true })
        console.log(`Token ${fileIndex} generated!`)
        tokenId++;

        if (tokenId < len) {
            await fn();
        } else {
            return;
        }
    };

    await fn();

    console.log('finish! generated images: ' + len)
    return zipResult;
}

export function downloadZip(zipFile) {
    // Generate the zip file asynchronously
    zipFile.generateAsync({type:"blob"})
        .then(function(blob) {
            // Force down of the Zip file
            saveAs(blob, "tokens.zip");
        });
}

function randomlySelectLayers(layers) {
    const mt = MersenneTwister19937.autoSeed()

    let selectedTraits = {}

    for (const layer of layers) {
        if (bool(layer.probability)(mt)) {
            let selected = pickWeighted(mt, layer.options)
            selectedTraits[layer.name] = { n: selected.name, f: selected.file }
        }
    }

    return {
        selectedTraits
    }
}

function pickWeighted(mt, options) {
    const weightSum = options.reduce((acc, option) => {
        return acc + (option.weight ?? 1.0)
    }, 0)

    const r = real(0.0, weightSum, false)(mt)

    let summedWeight = 0.0;
    for (const option of options) {
        summedWeight += option.weight ?? 1.0
        if (r <= summedWeight) {
            return option
        }
    }
}

function generateMetadata(tokenId, traits) {
    const attributes = []
    for (const [trait_type, value] of Object.entries(traits)) {
        attributes.push({trait_type, value})
    }

    return metadataTemplate(tokenId, attributes)
}

var metadataTemplate = (tokenId, attributes) => ({
    image: '<%IMAGE_URL%>',
    name: `NFT #${tokenId}`,
    external_url: 'https://www.google.com/',
    description: 'A demo collection!',
    attributes: attributes,
})