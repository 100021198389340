export const generator = ({
    headerTitle,
    headerImage,
	weatherTemp, 
    weatherIcon,
    weatherTitle,
	weatherStatus,
    avatarImage,
    avatarTitle,
    avatarSubtitle
}) => {
const date = new Date();
return (
`
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1892.8 2676.7" enable-background="new 0 0 1892.8 2676.7" xml:space="preserve">
	 <style>
		.center { text-align: center }
		.upper { text-transform: uppercase }
		.base { font-family: Tahoma; font-size: 2em; color: #444 }
		.base2 { font-family: Tahoma; font-size: 2em; color: #fff }
		.title1 { font-family: Tahoma; font-weight: bold; font-size: 4.2em; color: #fff }
		.title2 { font-family: Tahoma; font-weight: bold; font-size: 3.5em; color: #444 }
		.title3 { font-family: Tahoma; font-weight: bold; font-size: 3.5em; color: #c35649 }
		.title4 { font-family: "Times New Roman"; font-weight: bold; font-size: 11.2em; color: #c35649 }
		.title5 { font-family: "Tahoma"; font-weight: bold; font-size: 10.2em; color: #444 }
		.title6 { font-family: "Tahoma"; font-size: 4.6em; color: #444 }
	 </style>
<g>
	<rect x="70.1" y="99.1" fill="#EBEBEB" width="1752.6" height="2478.4"/>
	<g opacity="0.9" >
		<image x="160" y="640" xmlns="http://www.w3.org/2000/svg" width="1580" height="1130" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="${headerImage}"></image>
	</g>
	<rect x="206.1" y="1370" opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20" width="1518.8" height="400"/>
	<g>
		<rect x="175.8" y="1370" fill-rule="evenodd" clip-rule="evenodd" fill="#C35649" width="43.8" height="400"/>
	</g>
	<g>
		<rect x="154.8" y="205" fill="#231F20" width="1571.1" height="9.6"/>
	</g>
	<rect x="1453.4" y="177.5" fill="#231F20" width="272.4" height="33.4"/>
	<g>
		<rect x="1555.6" y="401.8" fill="#231F20" width="117.3" height="3.2"/>
		<rect x="1555.6" y="390" fill="#231F20" width="117.3" height="5.8"/>
		<rect x="1555.6" y="383.4" fill="#231F20" width="117.3" height="2"/>
		<rect x="1555.6" y="368" fill="#231F20" width="117.3" height="4.6"/>
		<rect x="1555.6" y="344.9" fill="#231F20" width="117.3" height="4.6"/>
		<rect x="1555.6" y="336.4" fill="#231F20" width="117.3" height="1.8"/>
		<rect x="1555.6" y="330.3" fill="#231F20" width="117.3" height="3.4"/>
		<rect x="1555.6" y="318" fill="#231F20" width="117.3" height="4.6"/>
		<rect x="1555.6" y="307.1" fill="#231F20" width="117.3" height="2.6"/>
		<rect x="1555.6" y="293.2" fill="#231F20" width="117.3" height="3.2"/>
		<rect x="1555.6" y="288.9" fill="#231F20" width="117.3" height="2"/>
		<rect x="1555.6" y="281.7" fill="#231F20" width="117.3" height="2.5"/>
		<rect x="1555.6" y="414.2" fill="#231F20" width="117.3" height="7.5"/>
		<rect x="1555.6" y="407.8" fill="#231F20" width="117.3" height="1.7"/>
		<rect x="1555.6" y="428.4" fill="#231F20" width="117.3" height="2.5"/>
		<rect x="1555.6" y="437.1" fill="#231F20" width="117.3" height="2.1"/>
		<rect x="1555.6" y="361.9" fill="#231F20" width="140.8" height="2.5"/>
		<rect x="1555.6" y="353.7" fill="#231F20" width="140.8" height="2.3"/>
		<rect x="1555.6" y="269.9" fill="#231F20" width="140.8" height="2.9"/>
		<rect x="1555.6" y="263" fill="#231F20" width="140.8" height="4.6"/>
		<rect x="1555.6" y="449.8" fill="#231F20" width="140.8" height="5.2"/>
		<rect x="1555.6" y="457.4" fill="#231F20" width="140.8" height="2.4"/>
		<g>
			<path fill="#231F20" d="M1691.2,425.2c8.5,0,12.8,3.3,12.8,9.9c0,1.1-0.1,2.1-0.3,2.7h-2.1c0.3-0.8,0.4-1.7,0.4-2.7
				c0-2.4-0.7-4.2-2.2-5.4c-1.5-1.2-3.7-1.9-6.8-2v0.2c0.8,0.5,1.5,1.3,1.9,2.2c0.4,0.9,0.7,1.9,0.7,3.1c0,1.9-0.6,3.4-1.7,4.6
				c-1.2,1.1-2.8,1.7-4.8,1.7c-2.3,0-4.1-0.6-5.4-1.9c-1.3-1.3-2-2.9-2-5c0-1.5,0.4-2.8,1.1-3.9c0.8-1.1,1.9-2,3.3-2.6
				C1687.5,425.5,1689.2,425.2,1691.2,425.2z M1683.7,432.6c0,1.4,0.5,2.5,1.4,3.3c0.9,0.8,2.2,1.2,3.8,1.2c1.4,0,2.6-0.4,3.4-1.1
				c0.8-0.7,1.2-1.8,1.2-3.3c0-0.9-0.2-1.7-0.6-2.5c-0.4-0.8-0.9-1.4-1.5-1.8c-0.6-0.4-1.3-0.7-2-0.7c-1,0-2,0.2-2.9,0.6
				c-0.9,0.4-1.6,1-2.1,1.7C1683.9,430.8,1683.7,431.6,1683.7,432.6z"/>
			<path fill="#231F20" d="M1692.7,407.7c3.8,0,6.6,0.6,8.4,1.8c1.9,1.2,2.8,3,2.8,5.4c0,2.4-1,4.1-2.8,5.3
				c-1.9,1.2-4.7,1.8-8.4,1.8c-3.8,0-6.6-0.6-8.4-1.8c-1.8-1.2-2.8-3-2.8-5.4c0-2.4,1-4.2,2.9-5.4
				C1686.3,408.3,1689.1,407.7,1692.7,407.7z M1692.7,419.6c3.2,0,5.5-0.4,6.9-1.1c1.4-0.7,2.2-1.9,2.2-3.6c0-1.6-0.7-2.8-2.2-3.6
				c-1.5-0.7-3.8-1.1-6.9-1.1c-3.1,0-5.4,0.4-6.9,1.1c-1.5,0.7-2.2,1.9-2.2,3.6c0,1.6,0.7,2.8,2.2,3.6
				C1687.3,419.2,1689.6,419.6,1692.7,419.6z"/>
			<path fill="#231F20" d="M1687,391.2c1.4,0,2.5,0.4,3.4,1.2c0.9,0.8,1.5,1.9,1.8,3.3h0.1c0.2-1.7,0.8-3,1.7-3.9
				c0.9-0.8,2.1-1.3,3.5-1.3c2.1,0,3.7,0.7,4.8,2.2c1.1,1.4,1.7,3.5,1.7,6.1c0,1.1-0.1,2.2-0.3,3.2c-0.2,1-0.5,1.9-0.9,2.8h-2.4
				c0.5-0.9,0.8-1.9,1.1-3c0.2-1.1,0.4-2.1,0.4-3c0-3.8-1.5-5.6-4.4-5.6c-2.6,0-4,2.1-4,6.2v2.1h-2.1v-2.2c0-1.7-0.4-3-1.1-4
				c-0.7-1-1.8-1.5-3.1-1.5c-1.1,0-1.9,0.4-2.5,1.1c-0.6,0.7-0.9,1.7-0.9,3c0,0.9,0.1,1.9,0.4,2.7c0.3,0.8,0.7,1.8,1.4,2.9l-1.7,1.2
				c-0.7-0.9-1.3-1.9-1.7-3.1c-0.4-1.2-0.6-2.4-0.6-3.7c0-2.1,0.5-3.8,1.5-4.9C1684,391.8,1685.3,391.2,1687,391.2z"/>
			<path fill="#231F20" d="M1703.6,378.1v2.4h-15.5c-1.3,0-2.5,0-3.7-0.1c0.2,0.2,0.4,0.4,0.7,0.7c0.2,0.3,1.2,1.4,2.9,3.5l-1.7,1.3
				l-4.4-5.8v-2.1H1703.6z"/>
		</g>
		<g>
			<path fill="#231F20" d="M1703.6,337.3v2.4h-15.5c-1.3,0-2.5,0-3.7-0.1c0.2,0.2,0.4,0.4,0.7,0.7c0.2,0.3,1.2,1.4,2.9,3.5l-1.7,1.3
				l-4.4-5.8v-2.1H1703.6z"/>
			<path fill="#231F20" d="M1692.7,314.6c3.8,0,6.6,0.6,8.4,1.8c1.9,1.2,2.8,3,2.8,5.4c0,2.3-1,4.1-2.8,5.3
				c-1.9,1.2-4.7,1.8-8.4,1.8c-3.8,0-6.6-0.6-8.4-1.8c-1.8-1.2-2.8-3-2.8-5.4c0-2.4,1-4.2,2.9-5.4
				C1686.3,315.2,1689.1,314.6,1692.7,314.6z M1692.7,326.5c3.2,0,5.5-0.4,6.9-1.1c1.4-0.7,2.2-1.9,2.2-3.6c0-1.6-0.7-2.8-2.2-3.6
				c-1.5-0.7-3.8-1.1-6.9-1.1c-3.1,0-5.4,0.4-6.9,1.1c-1.5,0.7-2.2,1.9-2.2,3.6c0,1.6,0.7,2.8,2.2,3.6
				C1687.3,326.1,1689.6,326.5,1692.7,326.5z"/>
			<path fill="#231F20" d="M1703.6,302.4v2.4h-15.5c-1.3,0-2.5,0-3.7-0.1c0.2,0.2,0.4,0.4,0.7,0.7c0.2,0.3,1.2,1.4,2.9,3.5l-1.7,1.3
				l-4.4-5.8v-2.1H1703.6z"/>
			<path fill="#231F20" d="M1703.6,285v2.4h-15.5c-1.3,0-2.5,0-3.7-0.1c0.2,0.2,0.4,0.4,0.7,0.7c0.2,0.3,1.2,1.4,2.9,3.5l-1.7,1.3
				l-4.4-5.8V285H1703.6z"/>
		</g>
	</g>
	<rect x="153.6" y="499.5" fill="#231F20" width="1178.5" height="3.1"/>
	<path fill="#C35649" d="M1506.9,544.4c0,46.8-38,84.8-84.8,84.8c-46.8,0-84.8-38-84.8-84.8c0-46.8,38-84.8,84.8-84.8
		C1469,459.6,1506.9,497.6,1506.9,544.4z"/>
	<rect x="1515.2" y="499.5" fill="#231F20" width="210" height="3.1"/>
	<g>
		<rect x="153" y="571.5" fill="#231F20" width="1177" height="3.1"/>
		<rect x="1517.2" y="571.5" fill="#231F20" width="207.4" height="3.1"/>
	</g>
	<g>
		<rect x="790.3" y="1836.8" fill="#231F20" width="6.8" height="625.8"/>
	</g>
	<g>
		<rect x="881.3" y="1918.7" fill="#231F20" width="827.4" height="6.8"/>
	</g>
	<g>
		<rect x="174.6" y="1917.6" fill="#231F20" width="545.5" height="6.8"/>
	</g>
	<g>
		<rect x="174.2" y="1976.6" fill="#231F20" width="545.5" height="6.8"/>
	</g>

	<g>
		<path fill="#FFFFFF" d="M1474.3,203.1h-2.1l-8.8-13.6h-0.1c0.1,1.6,0.2,3,0.2,4.4v9.2h-1.7v-16.2h2.1l8.8,13.5h0.1
			c0-0.2,0-0.8-0.1-1.9c-0.1-1.1-0.1-1.8-0.1-2.3v-9.3h1.8V203.1z"/>
		<path fill="#FFFFFF" d="M1492.7,195c0,2.6-0.7,4.6-2,6.1c-1.3,1.5-3.1,2.2-5.5,2.2c-2.4,0-4.2-0.7-5.5-2.2
			c-1.3-1.5-1.9-3.5-1.9-6.2c0-2.6,0.6-4.7,1.9-6.1c1.3-1.4,3.1-2.2,5.5-2.2c2.3,0,4.1,0.7,5.4,2.2
			C1492.1,190.4,1492.7,192.4,1492.7,195z M1479.9,195c0,2.2,0.5,3.8,1.4,5c0.9,1.1,2.3,1.7,4.1,1.7c1.8,0,3.1-0.6,4.1-1.7
			c0.9-1.1,1.4-2.8,1.4-5c0-2.2-0.5-3.8-1.4-4.9c-0.9-1.1-2.3-1.7-4-1.7c-1.8,0-3.2,0.6-4.1,1.7
			C1480.3,191.2,1479.9,192.8,1479.9,195z"/>
		<path fill="#FFFFFF" d="M1495.8,201.9c0-0.5,0.1-0.9,0.3-1.1c0.2-0.3,0.5-0.4,1-0.4c0.4,0,0.8,0.1,1,0.4c0.2,0.3,0.4,0.6,0.4,1.1
			c0,0.5-0.1,0.8-0.4,1.1c-0.2,0.3-0.6,0.4-1,0.4c-0.4,0-0.7-0.1-0.9-0.3C1495.9,202.8,1495.8,202.4,1495.8,201.9z"/>
		<path fill="#FFFFFF" d="M1518.5,199.4h-2.4v3.7h-1.8v-3.7h-7.9v-1.6l7.7-10.9h1.9v10.9h2.4V199.4z M1514.4,197.7v-5.4
			c0-1.1,0-2.2,0.1-3.6h-0.1c-0.4,0.7-0.7,1.3-1,1.8l-5.1,7.2H1514.4z"/>
		<path fill="#FFFFFF" d="M1530.8,195c0,2.8-0.4,4.9-1.3,6.3c-0.9,1.4-2.2,2.1-4,2.1c-1.7,0-3.1-0.7-4-2.1c-0.9-1.4-1.4-3.5-1.4-6.2
			c0-2.8,0.4-4.9,1.3-6.3c0.9-1.4,2.2-2,4-2c1.8,0,3.1,0.7,4,2.1C1530.3,190.2,1530.8,192.3,1530.8,195z M1521.9,195
			c0,2.3,0.3,4.1,0.8,5.1c0.6,1.1,1.4,1.6,2.6,1.6c1.2,0,2.1-0.5,2.7-1.6c0.5-1.1,0.8-2.8,0.8-5.1c0-2.3-0.3-4-0.8-5.1
			c-0.6-1.1-1.4-1.6-2.7-1.6c-1.2,0-2.1,0.5-2.6,1.6C1522.2,190.9,1521.9,192.6,1521.9,195z"/>
		<path fill="#FFFFFF" d="M1533.6,201.9c0-0.5,0.1-0.9,0.3-1.1c0.2-0.3,0.5-0.4,1-0.4c0.4,0,0.8,0.1,1,0.4c0.2,0.3,0.4,0.6,0.4,1.1
			c0,0.5-0.1,0.8-0.4,1.1c-0.2,0.3-0.6,0.4-1,0.4c-0.4,0-0.7-0.1-0.9-0.3C1533.7,202.8,1533.6,202.4,1533.6,201.9z"/>
		<path fill="#FFFFFF" d="M1544.1,193.2c1.7,0,3,0.4,4,1.3c1,0.8,1.5,2,1.5,3.5c0,1.7-0.5,3-1.6,3.9c-1.1,0.9-2.5,1.4-4.4,1.4
			c-1.8,0-3.2-0.3-4.2-0.9v-1.8c0.5,0.3,1.2,0.6,1.9,0.8c0.8,0.2,1.5,0.3,2.3,0.3c1.3,0,2.3-0.3,3-0.9c0.7-0.6,1.1-1.5,1.1-2.6
			c0-2.3-1.4-3.4-4.1-3.4c-0.7,0-1.6,0.1-2.8,0.3l-0.9-0.6l0.6-7.6h8v1.7h-6.5l-0.4,4.9C1542.4,193.3,1543.2,193.2,1544.1,193.2z"/>
		<path fill="#FFFFFF" d="M1562,190.7c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1.1-2.5,1.3v0.1c1.3,0.2,2.3,0.6,2.9,1.2
			c0.6,0.7,0.9,1.5,0.9,2.6c0,1.5-0.5,2.7-1.6,3.6c-1.1,0.8-2.6,1.2-4.6,1.2c-0.9,0-1.6-0.1-2.3-0.2c-0.7-0.1-1.4-0.4-2.1-0.7v-1.7
			c0.7,0.3,1.4,0.6,2.2,0.8c0.8,0.2,1.5,0.3,2.2,0.3c2.8,0,4.2-1.1,4.2-3.3c0-2-1.5-2.9-4.6-2.9h-1.6V194h1.6c1.3,0,2.3-0.3,3-0.8
			c0.7-0.6,1.1-1.3,1.1-2.3c0-0.8-0.3-1.4-0.8-1.9c-0.5-0.5-1.3-0.7-2.2-0.7c-0.7,0-1.4,0.1-2,0.3c-0.6,0.2-1.3,0.5-2.1,1.1
			l-0.9-1.2c0.7-0.5,1.4-0.9,2.3-1.2c0.9-0.3,1.8-0.4,2.7-0.4c1.6,0,2.8,0.4,3.7,1.1C1561.5,188.5,1562,189.5,1562,190.7z"/>
		<path fill="#FFFFFF" d="M1565.1,196.2c0-3.2,0.6-5.5,1.9-7.1c1.2-1.6,3.1-2.4,5.5-2.4c0.8,0,1.5,0.1,2,0.2v1.6
			c-0.6-0.2-1.2-0.3-1.9-0.3c-1.7,0-3.1,0.5-4,1.6c-0.9,1.1-1.4,2.8-1.5,5.1h0.1c0.8-1.3,2.1-1.9,3.8-1.9c1.5,0,2.6,0.4,3.4,1.3
			c0.8,0.9,1.3,2.1,1.3,3.6c0,1.7-0.5,3-1.4,4c-0.9,1-2.2,1.4-3.7,1.4c-1.7,0-3-0.6-4-1.9C1565.6,200.2,1565.1,198.4,1565.1,196.2z
			 M1570.5,201.8c1,0,1.9-0.3,2.4-1c0.6-0.7,0.9-1.6,0.9-2.9c0-1.1-0.3-1.9-0.8-2.5c-0.5-0.6-1.3-0.9-2.4-0.9
			c-0.7,0-1.3,0.1-1.8,0.4c-0.6,0.3-1,0.6-1.3,1.1c-0.3,0.5-0.5,1-0.5,1.5c0,0.8,0.1,1.5,0.4,2.1c0.3,0.7,0.7,1.2,1.3,1.6
			C1569.2,201.6,1569.8,201.8,1570.5,201.8z"/>
	</g>
	<g>
		<path fill="#FFFFFF" d="M1673.8,198.1c0,1-0.4,1.8-1.1,2.5c-0.8,0.7-1.8,1.1-3.1,1.2v2.6h-1.4v-2.5c-0.8,0-1.6-0.1-2.4-0.2
			c-0.8-0.1-1.4-0.3-1.9-0.5v-1.7c0.6,0.3,1.3,0.5,2.1,0.7c0.8,0.2,1.5,0.3,2.2,0.3v-4.9c-1.5-0.5-2.6-1-3.2-1.7
			c-0.6-0.6-0.9-1.5-0.9-2.5c0-1,0.4-1.8,1.1-2.4c0.7-0.6,1.7-1,3-1.1v-2h1.4v2c1.4,0,2.7,0.3,3.9,0.8l-0.6,1.4
			c-1.1-0.4-2.2-0.7-3.3-0.8v4.8c1.2,0.4,2,0.7,2.6,1.1c0.6,0.3,1,0.8,1.3,1.2C1673.7,196.9,1673.8,197.5,1673.8,198.1z
			 M1665.9,191.4c0,0.6,0.2,1,0.5,1.3c0.3,0.3,0.9,0.7,1.7,1v-4.3c-0.7,0.1-1.3,0.3-1.7,0.7C1666.1,190.5,1665.9,190.9,1665.9,191.4
			z M1671.9,198.3c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-1-0.7-1.9-1v4.3C1671.1,200.1,1671.9,199.4,1671.9,198.3z"/>
		<path fill="#FFFFFF" d="M1683.2,203.1h-1.8v-11.5c0-1,0-1.9,0.1-2.7c-0.2,0.2-0.3,0.3-0.5,0.5c-0.2,0.2-1.1,0.9-2.6,2.2l-1-1.3
			l4.3-3.3h1.5V203.1z"/>
		<path fill="#FFFFFF" d="M1689.9,201.9c0-0.5,0.1-0.9,0.3-1.1c0.2-0.3,0.5-0.4,1-0.4c0.4,0,0.8,0.1,1,0.4c0.2,0.3,0.4,0.6,0.4,1.1
			c0,0.5-0.1,0.8-0.4,1.1c-0.2,0.3-0.6,0.4-1,0.4c-0.4,0-0.7-0.1-0.9-0.3C1690,202.8,1689.9,202.4,1689.9,201.9z"/>
		<path fill="#FFFFFF" d="M1706,193.8c0,6.3-2.4,9.5-7.3,9.5c-0.9,0-1.5-0.1-2-0.2v-1.6c0.6,0.2,1.3,0.3,2,0.3c1.8,0,3.1-0.5,4-1.6
			c0.9-1.1,1.4-2.8,1.5-5h-0.1c-0.4,0.6-0.9,1.1-1.6,1.4c-0.7,0.3-1.4,0.5-2.3,0.5c-1.4,0-2.6-0.4-3.4-1.3c-0.8-0.9-1.3-2-1.3-3.6
			c0-1.7,0.5-3,1.4-4c0.9-1,2.2-1.5,3.7-1.5c1.1,0,2.1,0.3,2.9,0.8c0.8,0.6,1.5,1.4,1.9,2.5C1705.8,191.1,1706,192.4,1706,193.8z
			 M1700.6,188.3c-1.1,0-1.9,0.3-2.4,1c-0.6,0.7-0.9,1.6-0.9,2.8c0,1.1,0.3,1.9,0.8,2.5c0.5,0.6,1.3,0.9,2.4,0.9
			c0.7,0,1.3-0.1,1.8-0.4c0.6-0.3,1-0.6,1.3-1.1c0.3-0.5,0.5-1,0.5-1.5c0-0.8-0.2-1.5-0.5-2.1c-0.3-0.7-0.7-1.2-1.3-1.5
			C1701.9,188.5,1701.3,188.3,1700.6,188.3z"/>
		<path fill="#FFFFFF" d="M1713.4,193.2c1.7,0,3,0.4,4,1.3c1,0.8,1.5,2,1.5,3.5c0,1.7-0.5,3-1.6,3.9c-1.1,0.9-2.5,1.4-4.4,1.4
			c-1.8,0-3.2-0.3-4.2-0.9v-1.8c0.5,0.3,1.2,0.6,1.9,0.8c0.8,0.2,1.5,0.3,2.3,0.3c1.3,0,2.3-0.3,3-0.9c0.7-0.6,1.1-1.5,1.1-2.6
			c0-2.3-1.4-3.4-4.1-3.4c-0.7,0-1.6,0.1-2.8,0.3l-1-0.6l0.6-7.6h8v1.7h-6.5l-0.4,4.9C1711.7,193.3,1712.5,193.2,1713.4,193.2z"/>
	</g>
	<g>
		<path fill="#FFFFFF" d="M1405.4,553.3H1393v-34l0.1-5.6l0.2-6.1c-2.1,2.1-3.5,3.4-4.3,4.1l-6.8,5.4l-6-7.5l18.9-15.1h10.2V553.3z"
			/>
		<path fill="#FFFFFF" d="M1464.1,541.1h-7.1v12.2h-12.1v-12.2h-25.1v-8.6l25.8-37.9h11.5v36.9h7.1V541.1z M1444.9,531.5v-10
			c0-1.7,0.1-4.1,0.2-7.2c0.1-3.2,0.2-5,0.3-5.5h-0.3c-1,2.2-2.2,4.3-3.6,6.4l-10.8,16.3H1444.9z"/>
		<path fill="#FFFFFF" d="M1391.8,572.3c0,2.1-0.6,3.6-1.9,4.7c-1.3,1.1-3.1,1.6-5.5,1.6h-1.7v6.8h-4v-19.1h6.1
			c2.3,0,4.1,0.5,5.3,1.5C1391.2,568.8,1391.8,570.3,1391.8,572.3z M1382.7,575.3h1.3c1.2,0,2.2-0.2,2.8-0.7
			c0.6-0.5,0.9-1.2,0.9-2.1c0-0.9-0.3-1.7-0.8-2.1c-0.5-0.5-1.3-0.7-2.4-0.7h-1.8V575.3z"/>
		<path fill="#FFFFFF" d="M1407.1,585.4l-1.4-4.5h-7l-1.4,4.5h-4.4l6.7-19.2h5l6.8,19.2H1407.1z M1404.8,577.5c-1.3-4.1-2-6.4-2.2-7
			c-0.2-0.5-0.3-1-0.3-1.3c-0.3,1.1-1.1,3.9-2.5,8.3H1404.8z"/>
		<path fill="#FFFFFF" d="M1421.1,574.7h7.6v9.9c-1.2,0.4-2.4,0.7-3.5,0.8c-1.1,0.2-2.2,0.2-3.3,0.2c-2.9,0-5.1-0.8-6.6-2.5
			c-1.5-1.7-2.3-4.1-2.3-7.3c0-3.1,0.9-5.5,2.6-7.2c1.8-1.7,4.2-2.6,7.3-2.6c2,0,3.8,0.4,5.7,1.2l-1.3,3.2c-1.4-0.7-2.8-1-4.3-1
			c-1.7,0-3.2,0.6-4.2,1.8c-1.1,1.2-1.6,2.8-1.6,4.7c0,2.1,0.4,3.7,1.3,4.7c0.9,1.1,2.1,1.6,3.7,1.6c0.8,0,1.7-0.1,2.6-0.3v-4h-3.6
			V574.7z"/>
		<path fill="#FFFFFF" d="M1444.2,585.4h-11v-19.1h11v3.3h-6.9v4.2h6.5v3.3h-6.5v4.9h6.9V585.4z"/>
		<path fill="#FFFFFF" d="M1459.5,580.1c0,1.7-0.6,3.1-1.9,4.1c-1.2,1-3,1.5-5.2,1.5c-2,0-3.8-0.4-5.4-1.1v-3.8
			c1.3,0.6,2.4,1,3.3,1.2c0.9,0.2,1.7,0.4,2.4,0.4c0.9,0,1.6-0.2,2-0.5c0.5-0.3,0.7-0.8,0.7-1.5c0-0.4-0.1-0.7-0.3-1
			c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.3-1.2-0.7-2.5-1.3c-1.2-0.5-2-1.1-2.6-1.6c-0.6-0.5-1-1.1-1.4-1.8c-0.3-0.7-0.5-1.5-0.5-2.4
			c0-1.7,0.6-3,1.7-4c1.1-1,2.7-1.4,4.7-1.4c1,0,1.9,0.1,2.8,0.4c0.9,0.2,1.8,0.6,2.8,1l-1.3,3.1c-1-0.4-1.9-0.7-2.5-0.9
			c-0.7-0.2-1.3-0.2-2-0.2c-0.8,0-1.4,0.2-1.8,0.5c-0.4,0.4-0.6,0.8-0.6,1.4c0,0.4,0.1,0.7,0.2,0.9c0.2,0.3,0.4,0.5,0.8,0.8
			c0.4,0.2,1.2,0.7,2.6,1.3c1.8,0.9,3,1.7,3.7,2.6C1459.1,577.8,1459.5,578.9,1459.5,580.1z"/>
	</g>
	<g>
		<path fill="#231F20" d="M182,544.8h-1.2v-16.5h-5.7v-1.2h12.7v1.2H182V544.8z"/>
		<path fill="#231F20" d="M205.2,536c0,2.8-0.7,5-2.1,6.6c-1.4,1.6-3.3,2.4-5.8,2.4c-2.5,0-4.4-0.8-5.8-2.4
			c-1.4-1.6-2.1-3.8-2.1-6.7c0-2.8,0.7-5,2.1-6.6c1.4-1.6,3.3-2.4,5.8-2.4c2.5,0,4.4,0.8,5.8,2.4C204.5,531,205.2,533.2,205.2,536z
			 M190.8,536c0,2.5,0.6,4.5,1.7,5.9c1.1,1.4,2.7,2.1,4.8,2.1c2.1,0,3.7-0.7,4.9-2c1.1-1.4,1.7-3.3,1.7-5.9c0-2.5-0.6-4.5-1.7-5.8
			c-1.1-1.4-2.7-2-4.8-2c-2.1,0-3.7,0.7-4.9,2.1C191.3,531.5,190.8,533.5,190.8,536z"/>
		<path fill="#231F20" d="M219.8,532.3c0,1.7-0.6,3-1.7,3.9c-1.2,0.9-2.8,1.4-4.9,1.4h-2.7v7.3h-1.2v-17.6h4.3
			C217.7,527.2,219.8,528.9,219.8,532.3z M210.5,536.5h2.4c2,0,3.4-0.3,4.3-1c0.9-0.7,1.3-1.7,1.3-3.2c0-1.4-0.4-2.4-1.3-3
			c-0.8-0.7-2.1-1-3.9-1h-2.9V536.5z"/>
		<path fill="#231F20" d="M239.8,540.3c0,1.5-0.5,2.6-1.6,3.5c-1.1,0.9-2.5,1.3-4.3,1.3c-2.2,0-3.8-0.2-5-0.7v-1.2
			c1.3,0.5,2.9,0.8,4.9,0.8c1.4,0,2.6-0.3,3.4-1c0.8-0.7,1.3-1.5,1.3-2.6c0-0.7-0.1-1.2-0.4-1.7c-0.3-0.4-0.7-0.8-1.4-1.2
			c-0.6-0.4-1.6-0.8-2.8-1.2c-1.8-0.6-3-1.3-3.7-2c-0.7-0.7-1-1.7-1-2.9c0-1.3,0.5-2.4,1.6-3.2c1-0.8,2.4-1.2,4-1.2
			c1.7,0,3.2,0.3,4.7,0.9l-0.4,1.1c-1.5-0.6-2.9-0.9-4.2-0.9c-1.3,0-2.3,0.3-3.1,0.9c-0.8,0.6-1.2,1.4-1.2,2.5
			c0,0.7,0.1,1.2,0.4,1.6c0.2,0.4,0.6,0.8,1.2,1.1c0.5,0.3,1.5,0.7,2.8,1.2c1.4,0.5,2.4,0.9,3.1,1.4c0.7,0.4,1.2,1,1.5,1.5
			C239.6,538.8,239.8,539.5,239.8,540.3z"/>
		<path fill="#231F20" d="M248.1,544.8h-1.2v-16.5h-5.7v-1.2h12.7v1.2h-5.7V544.8z"/>
		<path fill="#231F20" d="M271.3,536c0,2.8-0.7,5-2.1,6.6c-1.4,1.6-3.3,2.4-5.8,2.4c-2.5,0-4.4-0.8-5.8-2.4
			c-1.4-1.6-2.1-3.8-2.1-6.7c0-2.8,0.7-5,2.1-6.6c1.4-1.6,3.3-2.4,5.8-2.4c2.5,0,4.4,0.8,5.8,2.4C270.6,531,271.3,533.2,271.3,536z
			 M256.9,536c0,2.5,0.6,4.5,1.7,5.9c1.1,1.4,2.7,2.1,4.8,2.1c2.1,0,3.7-0.7,4.9-2c1.1-1.4,1.7-3.3,1.7-5.9c0-2.5-0.6-4.5-1.7-5.8
			c-1.1-1.4-2.7-2-4.8-2c-2.1,0-3.7,0.7-4.9,2.1C257.4,531.5,256.9,533.5,256.9,536z"/>
		<path fill="#231F20" d="M276.6,537.1v7.7h-1.2v-17.6h4.2c2.2,0,3.8,0.4,4.8,1.2c1,0.8,1.6,2,1.6,3.6c0,1.2-0.3,2.2-0.9,3
			c-0.6,0.8-1.6,1.4-2.8,1.7l4.8,8h-1.5l-4.5-7.7H276.6z M276.6,536.1h3.4c1.5,0,2.6-0.3,3.5-1c0.8-0.7,1.2-1.6,1.2-2.9
			c0-1.3-0.4-2.3-1.2-2.9c-0.8-0.6-2.1-0.9-3.9-0.9h-2.9V536.1z"/>
		<path fill="#231F20" d="M290,544.8v-17.6h1.2v17.6H290z"/>
		<path fill="#231F20" d="M305.8,544.8h-9.6v-17.6h9.6v1.1h-8.4v6.7h7.9v1.1h-7.9v7.6h8.4V544.8z"/>
		<path fill="#231F20" d="M319.6,540.3c0,1.5-0.5,2.6-1.6,3.5c-1.1,0.9-2.5,1.3-4.3,1.3c-2.2,0-3.8-0.2-5-0.7v-1.2
			c1.3,0.5,2.9,0.8,4.9,0.8c1.4,0,2.6-0.3,3.4-1c0.9-0.7,1.3-1.5,1.3-2.6c0-0.7-0.1-1.2-0.4-1.7c-0.3-0.4-0.7-0.8-1.4-1.2
			c-0.6-0.4-1.6-0.8-2.8-1.2c-1.8-0.6-3-1.3-3.7-2c-0.7-0.7-1-1.7-1-2.9c0-1.3,0.5-2.4,1.6-3.2c1-0.8,2.4-1.2,4-1.2
			c1.7,0,3.2,0.3,4.7,0.9l-0.4,1.1c-1.5-0.6-2.9-0.9-4.2-0.9c-1.3,0-2.3,0.3-3.1,0.9c-0.8,0.6-1.2,1.4-1.2,2.5
			c0,0.7,0.1,1.2,0.4,1.6c0.2,0.4,0.6,0.8,1.2,1.1c0.5,0.3,1.5,0.7,2.8,1.2c1.4,0.5,2.4,0.9,3.1,1.4c0.7,0.4,1.2,1,1.5,1.5
			C319.4,538.8,319.6,539.5,319.6,540.3z"/>
		<path fill="#231F20" d="M335.3,527.2l-6.6,17.6h-1.3l6.6-17.6H335.3z"/>
		<path fill="#231F20" d="M344.5,544.8v-17.6h1.2v16.5h8.4v1.2H344.5z"/>
		<path fill="#231F20" d="M365.7,538.6h-7.2l-2.5,6.2h-1.4l7.1-17.7h0.8l6.9,17.7h-1.4L365.7,538.6z M359,537.5h6.3l-2.4-6.4
			c-0.2-0.5-0.4-1.2-0.7-2.1c-0.2,0.8-0.5,1.5-0.7,2.1L359,537.5z"/>
		<path fill="#231F20" d="M381.7,540.3c0,1.5-0.5,2.6-1.6,3.5c-1.1,0.9-2.5,1.3-4.3,1.3c-2.2,0-3.8-0.2-5-0.7v-1.2
			c1.3,0.5,2.9,0.8,4.9,0.8c1.4,0,2.6-0.3,3.4-1c0.9-0.7,1.3-1.5,1.3-2.6c0-0.7-0.1-1.2-0.4-1.7c-0.3-0.4-0.7-0.8-1.4-1.2
			c-0.6-0.4-1.6-0.8-2.8-1.2c-1.8-0.6-3-1.3-3.7-2c-0.7-0.7-1-1.7-1-2.9c0-1.3,0.5-2.4,1.6-3.2c1-0.8,2.4-1.2,4-1.2
			c1.7,0,3.2,0.3,4.7,0.9l-0.4,1.1c-1.5-0.6-2.9-0.9-4.2-0.9c-1.3,0-2.3,0.3-3.1,0.9c-0.8,0.6-1.2,1.4-1.2,2.5
			c0,0.7,0.1,1.2,0.4,1.6c0.2,0.4,0.6,0.8,1.2,1.1c0.5,0.3,1.5,0.7,2.8,1.2c1.4,0.5,2.4,0.9,3.1,1.4c0.7,0.4,1.2,1,1.5,1.5
			C381.6,538.8,381.7,539.5,381.7,540.3z"/>
		<path fill="#231F20" d="M390,544.8h-1.2v-16.5h-5.7v-1.2h12.7v1.2H390V544.8z"/>
		<path fill="#231F20" d="M417.6,544.8h-1.2l-10.4-15.6h-0.1c0.1,1.9,0.1,3.3,0.1,4.2v11.4h-1.2v-17.6h1.2l10.4,15.6h0.1
			c-0.1-1.4-0.1-2.8-0.1-4.1v-11.5h1.2V544.8z"/>
		<path fill="#231F20" d="M432.2,544.8h-9.6v-17.6h9.6v1.1h-8.4v6.7h7.9v1.1h-7.9v7.6h8.4V544.8z"/>
		<path fill="#231F20" d="M450.3,544.8h-1l-3.9-13.6c-0.3-1.1-0.6-2-0.7-2.7c-0.1,0.7-0.3,1.5-0.6,2.4c-0.2,0.9-1.5,5.6-3.9,13.9h-1
			l-4.8-17.6h1.3l3.1,11.4c0.1,0.5,0.2,0.9,0.3,1.3c0.1,0.4,0.2,0.8,0.3,1.1c0.1,0.3,0.2,0.7,0.2,1c0.1,0.3,0.1,0.6,0.2,1
			c0.2-1.1,0.6-2.8,1.2-5l3-10.7h1.4l3.5,12.3c0.4,1.4,0.7,2.6,0.9,3.4c0.1-0.6,0.2-1.2,0.4-1.8c0.2-0.7,1.4-5.3,3.7-13.8h1.2
			L450.3,544.8z"/>
		<path fill="#231F20" d="M468,540.3c0,1.5-0.5,2.6-1.6,3.5c-1.1,0.9-2.5,1.3-4.3,1.3c-2.2,0-3.8-0.2-5-0.7v-1.2
			c1.3,0.5,2.9,0.8,4.9,0.8c1.4,0,2.6-0.3,3.4-1c0.8-0.7,1.3-1.5,1.3-2.6c0-0.7-0.1-1.2-0.4-1.7c-0.3-0.4-0.7-0.8-1.4-1.2
			c-0.6-0.4-1.6-0.8-2.8-1.2c-1.8-0.6-3-1.3-3.7-2c-0.7-0.7-1-1.7-1-2.9c0-1.3,0.5-2.4,1.6-3.2c1-0.8,2.4-1.2,4-1.2
			c1.7,0,3.2,0.3,4.7,0.9l-0.4,1.1c-1.5-0.6-2.9-0.9-4.2-0.9c-1.3,0-2.3,0.3-3.1,0.9c-0.8,0.6-1.2,1.4-1.2,2.5
			c0,0.7,0.1,1.2,0.4,1.6c0.2,0.4,0.6,0.8,1.2,1.1c0.5,0.3,1.5,0.7,2.8,1.2c1.4,0.5,2.4,0.9,3.1,1.4c0.7,0.4,1.2,1,1.5,1.5
			C467.8,538.8,468,539.5,468,540.3z"/>
		<path fill="#231F20" d="M483.7,527.2l-6.6,17.6h-1.3l6.6-17.6H483.7z"/>
		<path fill="#231F20" d="M502.6,544.8h-9.6v-17.6h9.6v1.1h-8.4v6.7h7.9v1.1h-7.9v7.6h8.4V544.8z"/>
		<path fill="#231F20" d="M517.3,544.8h-1.4l-5.2-8.2l-5.3,8.2h-1.4l5.9-9.2l-5.4-8.4h1.4l4.8,7.5l4.8-7.5h1.3l-5.5,8.4L517.3,544.8
			z"/>
		<path fill="#231F20" d="M527.4,528c-2.2,0-4,0.7-5.2,2.1c-1.3,1.4-1.9,3.3-1.9,5.8c0,2.5,0.6,4.5,1.8,5.9c1.2,1.4,2.9,2.1,5.1,2.1
			c1.5,0,2.8-0.2,4.1-0.6v1.1c-1.2,0.4-2.6,0.6-4.4,0.6c-2.5,0-4.4-0.8-5.8-2.4c-1.4-1.6-2.1-3.8-2.1-6.7c0-1.8,0.3-3.4,1-4.7
			c0.7-1.4,1.7-2.4,2.9-3.2c1.3-0.7,2.8-1.1,4.4-1.1c1.7,0,3.3,0.3,4.6,1l-0.5,1.1C530.1,528.4,528.8,528,527.4,528z"/>
		<path fill="#231F20" d="M535.2,544.8v-17.6h1.2v16.5h8.4v1.2H535.2z"/>
		<path fill="#231F20" d="M560.8,527.2v11.4c0,2-0.6,3.6-1.8,4.7c-1.2,1.1-2.8,1.7-4.9,1.7c-2,0-3.6-0.6-4.8-1.7
			c-1.1-1.1-1.7-2.7-1.7-4.8v-11.4h1.2v11.4c0,1.7,0.5,3,1.4,4c0.9,0.9,2.3,1.4,4,1.4c1.7,0,3-0.5,3.9-1.4c0.9-0.9,1.4-2.2,1.4-3.9
			v-11.5H560.8z"/>
		<path fill="#231F20" d="M575.3,540.3c0,1.5-0.5,2.6-1.6,3.5c-1.1,0.9-2.5,1.3-4.3,1.3c-2.2,0-3.8-0.2-5-0.7v-1.2
			c1.3,0.5,2.9,0.8,4.9,0.8c1.4,0,2.6-0.3,3.4-1c0.9-0.7,1.3-1.5,1.3-2.6c0-0.7-0.1-1.2-0.4-1.7c-0.3-0.4-0.7-0.8-1.4-1.2
			c-0.6-0.4-1.6-0.8-2.8-1.2c-1.8-0.6-3-1.3-3.7-2c-0.7-0.7-1-1.7-1-2.9c0-1.3,0.5-2.4,1.6-3.2c1-0.8,2.4-1.2,4-1.2
			c1.7,0,3.2,0.3,4.7,0.9l-0.4,1.1c-1.5-0.6-2.9-0.9-4.2-0.9c-1.3,0-2.3,0.3-3.1,0.9c-0.8,0.6-1.2,1.4-1.2,2.5
			c0,0.7,0.1,1.2,0.4,1.6c0.2,0.4,0.6,0.8,1.2,1.1c0.5,0.3,1.5,0.7,2.8,1.2c1.4,0.5,2.4,0.9,3.1,1.4c0.7,0.4,1.2,1,1.5,1.5
			C575.2,538.8,575.3,539.5,575.3,540.3z"/>
		<path fill="#231F20" d="M579,544.8v-17.6h1.2v17.6H579z"/>
		<path fill="#231F20" d="M595.7,527.2h1.3l-6.6,17.6h-1.1l-6.6-17.6h1.3l4.4,12c0.7,1.8,1.1,3.2,1.4,4.1c0.2-0.6,0.5-1.5,1-2.8
			L595.7,527.2z"/>
		<path fill="#231F20" d="M609.1,544.8h-9.6v-17.6h9.6v1.1h-8.4v6.7h7.9v1.1h-7.9v7.6h8.4V544.8z"/>
		<path fill="#231F20" d="M619.5,544.8v-17.6h1.2v17.6H619.5z"/>
		<path fill="#231F20" d="M638.6,544.8h-1.2l-10.4-15.6h-0.1c0.1,1.9,0.1,3.3,0.1,4.2v11.4h-1.2v-17.6h1.2l10.4,15.6h0.1
			c-0.1-1.4-0.1-2.8-0.1-4.1v-11.5h1.2V544.8z"/>
		<path fill="#231F20" d="M648.1,544.8h-1.2v-16.5h-5.7v-1.2h12.7v1.2h-5.7V544.8z"/>
		<path fill="#231F20" d="M666.1,544.8h-9.6v-17.6h9.6v1.1h-8.4v6.7h7.9v1.1h-7.9v7.6h8.4V544.8z"/>
		<path fill="#231F20" d="M671.3,537.1v7.7h-1.2v-17.6h4.2c2.2,0,3.8,0.4,4.8,1.2c1,0.8,1.6,2,1.6,3.6c0,1.2-0.3,2.2-0.9,3
			c-0.6,0.8-1.6,1.4-2.8,1.7l4.8,8h-1.5l-4.5-7.7H671.3z M671.3,536.1h3.4c1.5,0,2.6-0.3,3.5-1c0.8-0.7,1.2-1.6,1.2-2.9
			c0-1.3-0.4-2.3-1.2-2.9c-0.8-0.6-2.1-0.9-3.9-0.9h-2.9V536.1z"/>
		<path fill="#231F20" d="M695.2,527.2h1.3l-6.6,17.6h-1.1l-6.6-17.6h1.3l4.4,12c0.7,1.8,1.1,3.2,1.4,4.1c0.2-0.6,0.5-1.5,1-2.8
			L695.2,527.2z"/>
		<path fill="#231F20" d="M699,544.8v-17.6h1.2v17.6H699z"/>
		<path fill="#231F20" d="M714.9,544.8h-9.6v-17.6h9.6v1.1h-8.4v6.7h7.9v1.1h-7.9v7.6h8.4V544.8z"/>
		<path fill="#231F20" d="M732.9,544.8h-1l-3.9-13.6c-0.3-1.1-0.6-2-0.7-2.7c-0.1,0.7-0.3,1.5-0.6,2.4c-0.2,0.9-1.5,5.6-3.9,13.9h-1
			l-4.8-17.6h1.3l3.1,11.4c0.1,0.5,0.2,0.9,0.3,1.3c0.1,0.4,0.2,0.8,0.3,1.1c0.1,0.3,0.2,0.7,0.2,1c0.1,0.3,0.1,0.6,0.2,1
			c0.2-1.1,0.6-2.8,1.2-5l3-10.7h1.4l3.5,12.3c0.4,1.4,0.7,2.6,0.9,3.4c0.1-0.6,0.2-1.2,0.4-1.8c0.2-0.7,1.4-5.3,3.7-13.8h1.2
			L732.9,544.8z"/>
		<path fill="#231F20" d="M752.9,527.2l-6.6,17.6h-1.3l6.6-17.6H752.9z"/>
		<path fill="#231F20" d="M762.1,527.2h4.8c2.2,0,3.8,0.4,4.8,1.1c1,0.7,1.5,1.9,1.5,3.4c0,1-0.3,1.9-0.9,2.5
			c-0.6,0.7-1.5,1.1-2.7,1.3v0.1c1.4,0.2,2.4,0.7,3.1,1.3c0.7,0.7,1,1.6,1,2.8c0,1.6-0.5,2.9-1.6,3.7c-1.1,0.9-2.6,1.3-4.6,1.3h-5.4
			V527.2z M763.4,535h3.9c1.7,0,2.9-0.3,3.6-0.8c0.8-0.6,1.1-1.4,1.1-2.6c0-1.2-0.4-2-1.3-2.6c-0.8-0.5-2.1-0.8-3.8-0.8h-3.6V535z
			 M763.4,536.1v7.6h4.1c3.3,0,4.9-1.3,4.9-4c0-2.4-1.7-3.6-5.2-3.6H763.4z"/>
		<path fill="#231F20" d="M790.6,527.2v11.4c0,2-0.6,3.6-1.8,4.7c-1.2,1.1-2.8,1.7-4.9,1.7c-2,0-3.6-0.6-4.8-1.7
			c-1.1-1.1-1.7-2.7-1.7-4.8v-11.4h1.2v11.4c0,1.7,0.5,3,1.4,4c0.9,0.9,2.3,1.4,4,1.4c1.7,0,3-0.5,3.9-1.4c0.9-0.9,1.4-2.2,1.4-3.9
			v-11.5H790.6z"/>
		<path fill="#231F20" d="M805.1,540.3c0,1.5-0.5,2.6-1.6,3.5c-1.1,0.9-2.5,1.3-4.3,1.3c-2.2,0-3.8-0.2-5-0.7v-1.2
			c1.3,0.5,2.9,0.8,4.9,0.8c1.4,0,2.6-0.3,3.4-1c0.8-0.7,1.3-1.5,1.3-2.6c0-0.7-0.1-1.2-0.4-1.7c-0.3-0.4-0.7-0.8-1.4-1.2
			c-0.6-0.4-1.6-0.8-2.8-1.2c-1.8-0.6-3-1.3-3.7-2c-0.7-0.7-1-1.7-1-2.9c0-1.3,0.5-2.4,1.6-3.2c1-0.8,2.4-1.2,4-1.2
			c1.7,0,3.2,0.3,4.7,0.9l-0.4,1.1c-1.5-0.6-2.9-0.9-4.2-0.9c-1.3,0-2.3,0.3-3.1,0.9c-0.8,0.6-1.2,1.4-1.2,2.5
			c0,0.7,0.1,1.2,0.4,1.6c0.2,0.4,0.6,0.8,1.2,1.1c0.5,0.3,1.5,0.7,2.8,1.2c1.4,0.5,2.4,0.9,3.1,1.4c0.7,0.4,1.2,1,1.5,1.5
			C804.9,538.8,805.1,539.5,805.1,540.3z"/>
		<path fill="#231F20" d="M808.8,544.8v-17.6h1.2v17.6H808.8z"/>
		<path fill="#231F20" d="M827.8,544.8h-1.2l-10.4-15.6h-0.1c0.1,1.9,0.1,3.3,0.1,4.2v11.4H815v-17.6h1.2l10.4,15.6h0.1
			c-0.1-1.4-0.1-2.8-0.1-4.1v-11.5h1.2V544.8z"/>
		<path fill="#231F20" d="M842.5,544.8h-9.6v-17.6h9.6v1.1h-8.4v6.7h7.9v1.1h-7.9v7.6h8.4V544.8z"/>
		<path fill="#231F20" d="M856.2,540.3c0,1.5-0.5,2.6-1.6,3.5c-1.1,0.9-2.5,1.3-4.3,1.3c-2.2,0-3.8-0.2-5-0.7v-1.2
			c1.3,0.5,2.9,0.8,4.9,0.8c1.4,0,2.6-0.3,3.4-1c0.8-0.7,1.3-1.5,1.3-2.6c0-0.7-0.1-1.2-0.4-1.7c-0.3-0.4-0.7-0.8-1.4-1.2
			c-0.6-0.4-1.6-0.8-2.8-1.2c-1.8-0.6-3-1.3-3.7-2c-0.7-0.7-1-1.7-1-2.9c0-1.3,0.5-2.4,1.6-3.2c1-0.8,2.4-1.2,4-1.2
			c1.7,0,3.2,0.3,4.7,0.9l-0.4,1.1c-1.5-0.6-2.9-0.9-4.2-0.9c-1.3,0-2.3,0.3-3.1,0.9c-0.8,0.6-1.2,1.4-1.2,2.5
			c0,0.7,0.1,1.2,0.4,1.6c0.2,0.4,0.6,0.8,1.2,1.1c0.5,0.3,1.5,0.7,2.8,1.2c1.4,0.5,2.4,0.9,3.1,1.4c0.7,0.4,1.2,1,1.5,1.5
			C856,538.8,856.2,539.5,856.2,540.3z"/>
		<path fill="#231F20" d="M869.6,540.3c0,1.5-0.5,2.6-1.6,3.5c-1.1,0.9-2.5,1.3-4.3,1.3c-2.2,0-3.8-0.2-5-0.7v-1.2
			c1.3,0.5,2.9,0.8,4.9,0.8c1.4,0,2.6-0.3,3.4-1c0.8-0.7,1.3-1.5,1.3-2.6c0-0.7-0.1-1.2-0.4-1.7c-0.3-0.4-0.7-0.8-1.4-1.2
			c-0.6-0.4-1.6-0.8-2.8-1.2c-1.8-0.6-3-1.3-3.7-2c-0.7-0.7-1-1.7-1-2.9c0-1.3,0.5-2.4,1.6-3.2c1-0.8,2.4-1.2,4-1.2
			c1.7,0,3.2,0.3,4.7,0.9l-0.4,1.1c-1.5-0.6-2.9-0.9-4.2-0.9c-1.3,0-2.3,0.3-3.1,0.9c-0.8,0.6-1.2,1.4-1.2,2.5
			c0,0.7,0.1,1.2,0.4,1.6c0.2,0.4,0.6,0.8,1.2,1.1c0.5,0.3,1.5,0.7,2.8,1.2c1.4,0.5,2.4,0.9,3.1,1.4c0.7,0.4,1.2,1,1.5,1.5
			C869.5,538.8,869.6,539.5,869.6,540.3z"/>
		<path fill="#231F20" d="M885.4,527.2l-6.6,17.6h-1.3l6.6-17.6H885.4z"/>
		<path fill="#231F20" d="M904.3,540.3c0,1.5-0.5,2.6-1.6,3.5c-1.1,0.9-2.5,1.3-4.3,1.3c-2.2,0-3.8-0.2-5-0.7v-1.2
			c1.3,0.5,2.9,0.8,4.9,0.8c1.4,0,2.6-0.3,3.4-1c0.8-0.7,1.3-1.5,1.3-2.6c0-0.7-0.1-1.2-0.4-1.7c-0.3-0.4-0.7-0.8-1.4-1.2
			c-0.6-0.4-1.6-0.8-2.8-1.2c-1.8-0.6-3-1.3-3.7-2c-0.7-0.7-1-1.7-1-2.9c0-1.3,0.5-2.4,1.6-3.2c1-0.8,2.4-1.2,4-1.2
			c1.7,0,3.2,0.3,4.7,0.9l-0.4,1.1c-1.5-0.6-2.9-0.9-4.2-0.9c-1.3,0-2.3,0.3-3.1,0.9c-0.8,0.6-1.2,1.4-1.2,2.5
			c0,0.7,0.1,1.2,0.4,1.6c0.2,0.4,0.6,0.8,1.2,1.1c0.5,0.3,1.5,0.7,2.8,1.2c1.4,0.5,2.4,0.9,3.1,1.4c0.7,0.4,1.2,1,1.5,1.5
			C904.1,538.8,904.3,539.5,904.3,540.3z"/>
		<path fill="#231F20" d="M918.6,532.3c0,1.7-0.6,3-1.7,3.9c-1.2,0.9-2.8,1.4-4.9,1.4h-2.7v7.3H908v-17.6h4.3
			C916.5,527.2,918.6,528.9,918.6,532.3z M909.3,536.5h2.4c2,0,3.4-0.3,4.3-1c0.9-0.7,1.3-1.7,1.3-3.2c0-1.4-0.4-2.4-1.3-3
			c-0.8-0.7-2.1-1-3.9-1h-2.9V536.5z"/>
		<path fill="#231F20" d="M937.3,536c0,2.8-0.7,5-2.1,6.6c-1.4,1.6-3.3,2.4-5.8,2.4c-2.5,0-4.4-0.8-5.8-2.4
			c-1.4-1.6-2.1-3.8-2.1-6.7c0-2.8,0.7-5,2.1-6.6c1.4-1.6,3.3-2.4,5.8-2.4c2.5,0,4.4,0.8,5.8,2.4C936.6,531,937.3,533.2,937.3,536z
			 M922.9,536c0,2.5,0.6,4.5,1.7,5.9c1.1,1.4,2.7,2.1,4.8,2.1c2.1,0,3.7-0.7,4.9-2c1.1-1.4,1.7-3.3,1.7-5.9c0-2.5-0.6-4.5-1.7-5.8
			c-1.1-1.4-2.7-2-4.8-2c-2.1,0-3.7,0.7-4.9,2.1C923.4,531.5,922.9,533.5,922.9,536z"/>
		<path fill="#231F20" d="M942.6,537.1v7.7h-1.2v-17.6h4.2c2.2,0,3.8,0.4,4.8,1.2c1,0.8,1.6,2,1.6,3.6c0,1.2-0.3,2.2-0.9,3
			c-0.6,0.8-1.6,1.4-2.8,1.7l4.8,8h-1.5l-4.5-7.7H942.6z M942.6,536.1h3.4c1.5,0,2.6-0.3,3.5-1c0.8-0.7,1.2-1.6,1.2-2.9
			c0-1.3-0.4-2.3-1.2-2.9c-0.8-0.6-2.1-0.9-3.9-0.9h-2.9V536.1z"/>
		<path fill="#231F20" d="M960.6,544.8h-1.2v-16.5h-5.7v-1.2h12.7v1.2h-5.7V544.8z"/>
		<path fill="#231F20" d="M981,527.2l-6.6,17.6h-1.3l6.6-17.6H981z"/>
		<path fill="#231F20" d="M997.7,528c-2.2,0-4,0.7-5.2,2.1c-1.3,1.4-1.9,3.3-1.9,5.8c0,2.5,0.6,4.5,1.8,5.9c1.2,1.4,2.9,2.1,5.1,2.1
			c1.5,0,2.8-0.2,4.1-0.6v1.1c-1.2,0.4-2.6,0.6-4.4,0.6c-2.5,0-4.4-0.8-5.8-2.4c-1.4-1.6-2.1-3.8-2.1-6.7c0-1.8,0.3-3.4,1-4.7
			c0.7-1.4,1.7-2.4,2.9-3.2c1.3-0.7,2.8-1.1,4.4-1.1c1.7,0,3.3,0.3,4.6,1l-0.5,1.1C1000.5,528.4,999.1,528,997.7,528z"/>
		<path fill="#231F20" d="M1018.5,527.2v11.4c0,2-0.6,3.6-1.8,4.7c-1.2,1.1-2.8,1.7-4.9,1.7c-2,0-3.6-0.6-4.8-1.7
			c-1.1-1.1-1.7-2.7-1.7-4.8v-11.4h1.2v11.4c0,1.7,0.5,3,1.4,4c0.9,0.9,2.3,1.4,4,1.4c1.7,0,3-0.5,3.9-1.4c0.9-0.9,1.4-2.2,1.4-3.9
			v-11.5H1018.5z"/>
		<path fill="#231F20" d="M1023.3,544.8v-17.6h1.2v16.5h8.4v1.2H1023.3z"/>
		<path fill="#231F20" d="M1040.5,544.8h-1.2v-16.5h-5.7v-1.2h12.7v1.2h-5.7V544.8z"/>
		<path fill="#231F20" d="M1061.8,527.2v11.4c0,2-0.6,3.6-1.8,4.7c-1.2,1.1-2.8,1.7-4.9,1.7c-2,0-3.6-0.6-4.8-1.7
			c-1.1-1.1-1.7-2.7-1.7-4.8v-11.4h1.2v11.4c0,1.7,0.5,3,1.4,4c0.9,0.9,2.3,1.4,4,1.4c1.7,0,3-0.5,3.9-1.4c0.9-0.9,1.4-2.2,1.4-3.9
			v-11.5H1061.8z"/>
		<path fill="#231F20" d="M1067.8,537.1v7.7h-1.2v-17.6h4.2c2.2,0,3.8,0.4,4.8,1.2c1,0.8,1.6,2,1.6,3.6c0,1.2-0.3,2.2-0.9,3
			c-0.6,0.8-1.6,1.4-2.8,1.7l4.8,8h-1.5l-4.5-7.7H1067.8z M1067.8,536.1h3.4c1.5,0,2.6-0.3,3.5-1c0.8-0.7,1.2-1.6,1.2-2.9
			c0-1.3-0.4-2.3-1.2-2.9c-0.8-0.6-2.1-0.9-3.9-0.9h-2.9V536.1z"/>
		<path fill="#231F20" d="M1090.9,544.8h-9.6v-17.6h9.6v1.1h-8.4v6.7h7.9v1.1h-7.9v7.6h8.4V544.8z"/>
		<path fill="#231F20" d="M1106.9,527.2l-6.6,17.6h-1.3l6.6-17.6H1106.9z"/>
		<path fill="#231F20" d="M1120.7,544.8h-1.2v-16.5h-5.7v-1.2h12.7v1.2h-5.7V544.8z"/>
		<path fill="#231F20" d="M1130.3,537.1v7.7h-1.2v-17.6h4.2c2.2,0,3.8,0.4,4.8,1.2c1,0.8,1.6,2,1.6,3.6c0,1.2-0.3,2.2-0.9,3
			c-0.6,0.8-1.6,1.4-2.8,1.7l4.8,8h-1.5l-4.5-7.7H1130.3z M1130.3,536.1h3.4c1.5,0,2.6-0.3,3.5-1c0.8-0.7,1.2-1.6,1.2-2.9
			c0-1.3-0.4-2.3-1.2-2.9c-0.8-0.6-2.1-0.9-3.9-0.9h-2.9V536.1z"/>
		<path fill="#231F20" d="M1152.2,538.6h-7.2l-2.5,6.2h-1.4l7.1-17.7h0.8l6.9,17.7h-1.4L1152.2,538.6z M1145.5,537.5h6.3l-2.4-6.4
			c-0.2-0.5-0.4-1.2-0.7-2.1c-0.2,0.8-0.5,1.5-0.7,2.1L1145.5,537.5z"/>
		<path fill="#231F20" d="M1169,527.2h1.3l-6.6,17.6h-1.1l-6.6-17.6h1.3l4.4,12c0.7,1.8,1.1,3.2,1.4,4.1c0.2-0.6,0.5-1.5,1-2.8
			L1169,527.2z"/>
		<path fill="#231F20" d="M1182.4,544.8h-9.6v-17.6h9.6v1.1h-8.4v6.7h7.9v1.1h-7.9v7.6h8.4V544.8z"/>
		<path fill="#231F20" d="M1186.4,544.8v-17.6h1.2v16.5h8.4v1.2H1186.4z"/>
		<path fill="#231F20" d="M1211.1,527.2l-6.6,17.6h-1.3l6.6-17.6H1211.1z"/>
		<path fill="#231F20" d="M1228.1,544.8l-6.6-16.3h-0.1c0.1,1,0.1,2,0.1,3.1v13.2h-1.2v-17.6h1.9l6.2,15.5h0.1l6.2-15.5h1.9v17.6
			h-1.2v-13.4c0-0.9,0-1.9,0.1-2.9h-0.1l-6.6,16.2H1228.1z"/>
		<path fill="#231F20" d="M1251.3,544.8h-9.6v-17.6h9.6v1.1h-8.4v6.7h7.9v1.1h-7.9v7.6h8.4V544.8z"/>
		<path fill="#231F20" d="M1268.6,535.8c0,3-0.8,5.2-2.3,6.7c-1.6,1.5-3.8,2.3-6.8,2.3h-4.2v-17.6h4.8c2.8,0,5,0.7,6.4,2.2
			C1267.9,530.9,1268.6,533,1268.6,535.8z M1267.3,535.9c0-2.5-0.6-4.4-1.9-5.7c-1.3-1.3-3.2-1.9-5.6-1.9h-3.2v15.5h2.9
			C1264.7,543.7,1267.3,541.1,1267.3,535.9z"/>
		<path fill="#231F20" d="M1272.7,544.8v-17.6h1.2v17.6H1272.7z"/>
		<path fill="#231F20" d="M1287.4,538.6h-7.2l-2.5,6.2h-1.4l7.1-17.7h0.8l6.9,17.7h-1.4L1287.4,538.6z M1280.7,537.5h6.3l-2.4-6.4
			c-0.2-0.5-0.4-1.2-0.7-2.1c-0.2,0.8-0.5,1.5-0.7,2.1L1280.7,537.5z"/>
	</g>
	<g>
		<path fill="#231F20" d="M1214.8,199.2h-0.9l-3.5-12.5c-0.3-1-0.5-1.9-0.7-2.5c-0.1,0.6-0.3,1.4-0.5,2.2c-0.2,0.8-1.4,5.1-3.6,12.8
			h-1l-4.4-16.2h1.2l2.8,10.4c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.4,0.2,0.7,0.3,1c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.1,0.6,0.2,0.9
			c0.2-1,0.6-2.5,1.1-4.6l2.8-9.8h1.2l3.2,11.2c0.4,1.3,0.6,2.3,0.8,3.1c0.1-0.5,0.2-1.1,0.4-1.7c0.1-0.6,1.3-4.8,3.4-12.7h1.1
			L1214.8,199.2z"/>
		<path fill="#231F20" d="M1235,199.2h-0.9l-3.5-12.5c-0.3-1-0.5-1.9-0.7-2.5c-0.1,0.6-0.3,1.4-0.5,2.2c-0.2,0.8-1.4,5.1-3.6,12.8
			h-1l-4.4-16.2h1.2l2.8,10.4c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.4,0.2,0.7,0.3,1c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.1,0.6,0.2,0.9
			c0.2-1,0.6-2.5,1.1-4.6l2.8-9.8h1.2l3.2,11.2c0.4,1.3,0.6,2.3,0.8,3.1c0.1-0.5,0.2-1.1,0.4-1.7c0.1-0.6,1.3-4.8,3.4-12.7h1.1
			L1235,199.2z"/>
		<path fill="#231F20" d="M1255.1,199.2h-0.9l-3.5-12.5c-0.3-1-0.5-1.9-0.7-2.5c-0.1,0.6-0.3,1.4-0.5,2.2c-0.2,0.8-1.4,5.1-3.6,12.8
			h-1l-4.4-16.2h1.2l2.8,10.4c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.4,0.2,0.7,0.3,1c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.1,0.6,0.2,0.9
			c0.2-1,0.6-2.5,1.1-4.6l2.8-9.8h1.2l3.2,11.2c0.4,1.3,0.6,2.3,0.8,3.1c0.1-0.5,0.2-1.1,0.4-1.7c0.1-0.6,1.3-4.8,3.4-12.7h1.1
			L1255.1,199.2z"/>
		<path fill="#231F20" d="M1262,198.4c0-0.7,0.3-1.1,0.9-1.1c0.6,0,0.9,0.4,0.9,1.1c0,0.7-0.3,1.1-0.9,1.1
			C1262.2,199.5,1262,199.1,1262,198.4z"/>
		<path fill="#231F20" d="M1279.6,199.2h-1.1l-9.6-14.3h-0.1c0.1,1.7,0.1,3,0.1,3.9v10.5h-1.1v-16.2h1.1l9.6,14.3h0.1
			c-0.1-1.3-0.1-2.6-0.1-3.8v-10.5h1.1V199.2z"/>
		<path fill="#231F20" d="M1293,199.2h-8.8v-16.2h8.8v1h-7.7v6.1h7.3v1h-7.3v6.9h7.7V199.2z"/>
		<path fill="#231F20" d="M1309.5,199.2h-0.9l-3.5-12.5c-0.3-1-0.5-1.9-0.7-2.5c-0.1,0.6-0.3,1.4-0.5,2.2c-0.2,0.8-1.4,5.1-3.6,12.8
			h-1l-4.4-16.2h1.2l2.8,10.4c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.4,0.2,0.7,0.3,1c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.1,0.6,0.2,0.9
			c0.2-1,0.6-2.5,1.1-4.6l2.8-9.8h1.2l3.2,11.2c0.4,1.3,0.6,2.3,0.8,3.1c0.1-0.5,0.2-1.1,0.4-1.7c0.1-0.6,1.3-4.8,3.4-12.7h1.1
			L1309.5,199.2z"/>
		<path fill="#231F20" d="M1325.7,195.1c0,1.3-0.5,2.4-1.5,3.2c-1,0.8-2.3,1.2-4,1.2c-2,0-3.5-0.2-4.5-0.7v-1.1
			c1.2,0.5,2.7,0.7,4.5,0.7c1.3,0,2.4-0.3,3.2-0.9c0.8-0.6,1.2-1.4,1.2-2.4c0-0.6-0.1-1.1-0.4-1.5c-0.3-0.4-0.7-0.8-1.3-1.1
			c-0.6-0.3-1.4-0.7-2.6-1.1c-1.7-0.6-2.8-1.2-3.4-1.8c-0.6-0.7-0.9-1.5-0.9-2.6c0-1.2,0.5-2.2,1.4-3c0.9-0.8,2.2-1.1,3.7-1.1
			c1.5,0,2.9,0.3,4.3,0.9l-0.4,1c-1.3-0.6-2.6-0.8-3.8-0.8c-1.2,0-2.1,0.3-2.8,0.8c-0.7,0.6-1.1,1.3-1.1,2.3c0,0.6,0.1,1.1,0.3,1.5
			c0.2,0.4,0.6,0.7,1.1,1c0.5,0.3,1.3,0.7,2.5,1.1c1.3,0.4,2.2,0.9,2.8,1.3c0.6,0.4,1.1,0.9,1.4,1.4
			C1325.6,193.7,1325.7,194.3,1325.7,195.1z"/>
		<path fill="#231F20" d="M1338.9,187.7c0,1.6-0.5,2.8-1.6,3.6c-1.1,0.8-2.6,1.2-4.5,1.2h-2.5v6.7h-1.1v-16.2h4
			C1337,183.1,1338.9,184.6,1338.9,187.7z M1330.3,191.6h2.2c1.8,0,3.1-0.3,3.9-0.9c0.8-0.6,1.2-1.6,1.2-2.9c0-1.2-0.4-2.2-1.1-2.8
			c-0.8-0.6-2-0.9-3.6-0.9h-2.7V191.6z"/>
		<path fill="#231F20" d="M1350.2,193.5h-6.6l-2.3,5.7h-1.2l6.5-16.2h0.7l6.4,16.2h-1.3L1350.2,193.5z M1344,192.5h5.8l-2.2-5.8
			c-0.2-0.5-0.4-1.1-0.7-1.9c-0.2,0.7-0.4,1.3-0.7,1.9L1344,192.5z"/>
		<path fill="#231F20" d="M1365.7,187.7c0,1.6-0.5,2.8-1.6,3.6c-1.1,0.8-2.6,1.2-4.5,1.2h-2.5v6.7h-1.1v-16.2h4
			C1363.8,183.1,1365.7,184.6,1365.7,187.7z M1357.1,191.6h2.2c1.8,0,3.1-0.3,3.9-0.9c0.8-0.6,1.2-1.6,1.2-2.9
			c0-1.2-0.4-2.2-1.1-2.8c-0.8-0.6-2-0.9-3.6-0.9h-2.7V191.6z"/>
		<path fill="#231F20" d="M1378,199.2h-8.8v-16.2h8.8v1h-7.7v6.1h7.3v1h-7.3v6.9h7.7V199.2z"/>
		<path fill="#231F20" d="M1382.8,192.2v7h-1.1v-16.2h3.8c2,0,3.5,0.4,4.4,1.1c1,0.7,1.4,1.9,1.4,3.3c0,1.1-0.3,2-0.9,2.7
			c-0.6,0.7-1.4,1.3-2.6,1.6l4.4,7.4h-1.3l-4.2-7H1382.8z M1382.8,191.2h3.1c1.4,0,2.4-0.3,3.2-0.9c0.8-0.6,1.1-1.5,1.1-2.7
			c0-1.2-0.4-2.1-1.1-2.7c-0.7-0.6-1.9-0.8-3.6-0.8h-2.7V191.2z"/>
		<path fill="#231F20" d="M1394.6,198.4c0-0.7,0.3-1.1,0.9-1.1c0.6,0,0.9,0.4,0.9,1.1c0,0.7-0.3,1.1-0.9,1.1
			C1394.9,199.5,1394.6,199.1,1394.6,198.4z"/>
		<path fill="#231F20" d="M1404.7,199.2h-1.1v-15.1h-5.2v-1.1h11.6v1.1h-5.2V199.2z"/>
		<path fill="#231F20" d="M1416.6,199.2h-1.1v-15.1h-5.2v-1.1h11.6v1.1h-5.2V199.2z"/>
	</g>
	<g>
		<path fill="#231F20" d="M1535.4,548.5h-1.2v-16.5h-5.7v-1.2h12.7v1.2h-5.7V548.5z"/>
		<path fill="#231F20" d="M1553.3,548.5h-9.6v-17.6h9.6v1.1h-8.4v6.7h7.9v1.1h-7.9v7.6h8.4V548.5z"/>
		<path fill="#231F20" d="M1564.9,531.7c-2.2,0-4,0.7-5.2,2.1c-1.3,1.4-1.9,3.4-1.9,5.8c0,2.5,0.6,4.5,1.8,5.9
			c1.2,1.4,2.9,2.1,5.1,2.1c1.5,0,2.8-0.2,4.1-0.6v1.1c-1.2,0.4-2.6,0.6-4.4,0.6c-2.5,0-4.4-0.8-5.8-2.4c-1.4-1.6-2.1-3.8-2.1-6.7
			c0-1.8,0.3-3.4,1-4.7c0.7-1.4,1.7-2.4,2.9-3.2c1.3-0.7,2.8-1.1,4.4-1.1c1.7,0,3.3,0.3,4.6,1l-0.5,1.1
			C1567.6,532.1,1566.3,531.7,1564.9,531.7z"/>
		<path fill="#231F20" d="M1585.4,548.5h-1.2v-8.7h-10.3v8.7h-1.2v-17.6h1.2v7.8h10.3v-7.8h1.2V548.5z"/>
		<path fill="#231F20" d="M1595.8,544c0-1.1,0.3-2.1,0.9-2.8c0.6-0.8,1.7-1.6,3.3-2.5l-0.9-1c-0.5-0.6-0.9-1.1-1.2-1.7
			c-0.3-0.5-0.4-1.1-0.4-1.7c0-1.1,0.4-2.1,1.1-2.7c0.8-0.7,1.8-1,3.1-1c1.2,0,2.2,0.3,3,1c0.7,0.7,1.1,1.6,1.1,2.7
			c0,0.8-0.3,1.6-0.8,2.3c-0.6,0.7-1.6,1.5-3,2.3l5.4,5.5c0.5-0.6,0.9-1.2,1.3-1.9c0.3-0.7,0.6-1.5,0.9-2.4h1.2
			c-0.5,2-1.3,3.7-2.5,5.1l3.2,3.2h-1.6l-2.3-2.4c-0.7,0.7-1.4,1.3-2,1.6c-0.6,0.3-1.2,0.6-1.9,0.8c-0.7,0.2-1.5,0.3-2.3,0.3
			c-1.7,0-3-0.4-4-1.2C1596.3,546.7,1595.8,545.5,1595.8,544z M1601.3,547.7c1,0,2-0.2,2.8-0.5c0.9-0.3,1.7-0.9,2.5-1.8l-5.8-5.8
			c-1.1,0.6-1.9,1.1-2.4,1.5c-0.5,0.4-0.8,0.8-1.1,1.3c-0.2,0.5-0.3,1-0.3,1.7c0,1.1,0.4,2,1.1,2.7
			C1598.9,547.3,1600,547.7,1601.3,547.7z M1598.8,534.3c0,0.6,0.2,1.2,0.5,1.8c0.3,0.5,0.9,1.2,1.8,2.1c1.3-0.7,2.2-1.3,2.7-1.9
			c0.5-0.6,0.7-1.3,0.7-2c0-0.8-0.2-1.4-0.7-1.8c-0.5-0.5-1.2-0.7-2-0.7c-0.9,0-1.6,0.2-2.2,0.7
			C1599.1,532.9,1598.8,533.5,1598.8,534.3z"/>
		<path fill="#231F20" d="M1630.6,544c0,1.5-0.5,2.6-1.6,3.5c-1.1,0.9-2.5,1.3-4.3,1.3c-2.2,0-3.8-0.2-5-0.7v-1.2
			c1.3,0.5,2.9,0.8,4.9,0.8c1.4,0,2.6-0.3,3.4-1c0.8-0.7,1.3-1.5,1.3-2.6c0-0.7-0.1-1.2-0.4-1.7c-0.3-0.4-0.7-0.8-1.4-1.2
			c-0.6-0.4-1.6-0.8-2.8-1.2c-1.8-0.6-3-1.3-3.7-2c-0.7-0.7-1-1.7-1-2.9c0-1.3,0.5-2.4,1.6-3.2c1-0.8,2.4-1.2,4-1.2
			c1.7,0,3.2,0.3,4.7,0.9l-0.4,1.1c-1.5-0.6-2.9-0.9-4.2-0.9c-1.3,0-2.3,0.3-3.1,0.9c-0.8,0.6-1.2,1.4-1.2,2.5
			c0,0.7,0.1,1.2,0.4,1.6c0.2,0.4,0.6,0.8,1.2,1.1c0.5,0.3,1.5,0.7,2.8,1.2c1.4,0.5,2.4,0.9,3.1,1.4c0.7,0.4,1.2,1,1.5,1.5
			C1630.4,542.5,1630.6,543.2,1630.6,544z"/>
		<path fill="#231F20" d="M1641.8,531.7c-2.2,0-4,0.7-5.2,2.1c-1.3,1.4-1.9,3.4-1.9,5.8c0,2.5,0.6,4.5,1.8,5.9
			c1.2,1.4,2.9,2.1,5.1,2.1c1.5,0,2.8-0.2,4.1-0.6v1.1c-1.2,0.4-2.6,0.6-4.4,0.6c-2.5,0-4.4-0.8-5.8-2.4c-1.4-1.6-2.1-3.8-2.1-6.7
			c0-1.8,0.3-3.4,1-4.7c0.7-1.4,1.7-2.4,2.9-3.2c1.3-0.7,2.8-1.1,4.4-1.1c1.7,0,3.3,0.3,4.6,1l-0.5,1.1
			C1644.6,532.1,1643.2,531.7,1641.8,531.7z"/>
		<path fill="#231F20" d="M1649.6,548.5v-17.6h1.2v17.6H1649.6z"/>
		<path fill="#231F20" d="M1665.5,548.5h-9.6v-17.6h9.6v1.1h-8.4v6.7h7.9v1.1h-7.9v7.6h8.4V548.5z"/>
		<path fill="#231F20" d="M1682.3,548.5h-1.2l-10.4-15.6h-0.1c0.1,1.9,0.1,3.3,0.1,4.2v11.4h-1.2v-17.6h1.2l10.4,15.6h0.1
			c-0.1-1.4-0.1-2.8-0.1-4.1v-11.5h1.2V548.5z"/>
		<path fill="#231F20" d="M1694.8,531.7c-2.2,0-4,0.7-5.2,2.1c-1.3,1.4-1.9,3.4-1.9,5.8c0,2.5,0.6,4.5,1.8,5.9
			c1.2,1.4,2.9,2.1,5.1,2.1c1.5,0,2.8-0.2,4.1-0.6v1.1c-1.2,0.4-2.6,0.6-4.4,0.6c-2.5,0-4.4-0.8-5.8-2.4c-1.4-1.6-2.1-3.8-2.1-6.7
			c0-1.8,0.3-3.4,1-4.7c0.7-1.4,1.7-2.4,2.9-3.2c1.3-0.7,2.8-1.1,4.4-1.1c1.7,0,3.3,0.3,4.6,1l-0.5,1.1
			C1697.6,532.1,1696.2,531.7,1694.8,531.7z"/>
		<path fill="#231F20" d="M1712.2,548.5h-9.6v-17.6h9.6v1.1h-8.4v6.7h7.9v1.1h-7.9v7.6h8.4V548.5z"/>
	</g>
	

	<foreignObject x="8%" y="9%" width="1600" height="200">
		<div xmlns="http://www.w3.org/1999/xhtml" class="title4 upper">EVENTS TODAY</div>
	</foreignObject>
	<foreignObject x="9%" y="17%" width="1200" height="100">
		<div xmlns="http://www.w3.org/1999/xhtml" class="base">${date.toDateString()}</div>
	</foreignObject>
	<foreignObject x="13%" y="52%" width="1400" height="480">
		<div xmlns="http://www.w3.org/1999/xhtml" class="title1">${headerTitle}</div>
	</foreignObject>
	<foreignObject x="47%" y="69%" width="800" height="100">
		<div xmlns="http://www.w3.org/1999/xhtml" class="title3 upper center">${weatherTitle}</div> 
	</foreignObject>
	<foreignObject x="9%" y="69%" width="580" height="100">
		<div xmlns="http://www.w3.org/1999/xhtml" class="title2 upper center">${avatarTitle}</div>
	</foreignObject>
	<foreignObject x="9%" y="72%" width="560" height="100">
		<div xmlns="http://www.w3.org/1999/xhtml" class="base upper center">${avatarSubtitle}</div>
	</foreignObject>

	<g>
		<rect x="9%" y="75%" fill="#ddd" width="545" height="450"/>
		<image style="transform-origin: 50% 50%" x="20%" y="76%" xmlns="http://www.w3.org/2000/svg" height="400" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="${avatarImage}"></image>
	</g>
	<g>
		<rect x="47%" y="73%" fill="#ddd" width="810" height="500"/>
		<foreignObject x="71%" y="76%" width="560" height="400">
			<div xmlns="http://www.w3.org/1999/xhtml" class="title5">${weatherTemp}</div>
		</foreignObject>
		<foreignObject x="47%" y="86%" width="800" height="240">
			<div xmlns="http://www.w3.org/1999/xhtml" class="title6 center">${weatherStatus}</div>
		</foreignObject>
		${weatherIcon}
	</g>
</g>

</svg>
`
)}