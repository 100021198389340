import { useState } from "react";
import Modal from "../layout/Modal";
import Button from "../common/Button";

const ModalForm = ({ children, buttonTitle = "new collection" }) => {
    const [isShow, setIsShow] = useState(false);

	return (
		<Modal
            maxWidth="5xl"
            show={isShow}
            handleShow={setIsShow}
			activator={({ handleShow }) => (
                <Button onClick={() => handleShow(true)}>
                    <span className="text-md py-1 block">{buttonTitle}</span>
                </Button>
            )}
		>
			<div className="bg-slate-800 text-gray-100 pt-4 pb-8 px-8 rounded-md font-sans" style={{minWidth: "600px"}}>
				{
                    children
                }
			</div>
		</Modal>
	)
}

export default ModalForm