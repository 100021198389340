import { useMassMint } from "./context";
import Button from "../../common/Button";

const Generate = () => {
    const {
        data: { csvContent },
        fn: { mintCollection },
    } = useMassMint();

    const handleMintCollection = async () => {
        await mintCollection();
    }

    const headerKeys = csvContent
        ? Object.keys(Object.assign({}, ...csvContent))
        : [];

    return (
        <div className="text-white my-3">
            <div className="my-3">
                <Button onClick={() => handleMintCollection()}>Mint Collection</Button>{" "}
            </div>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-darkdeep text-white">
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1 ">
                            <h3 className="font-semibold text-md text-white">
                                Token List
                            </h3>
                        </div>
                    </div>
                </div>
                {csvContent !== null ? (
                    <div className="block w-full overflow-x-auto ">
                        <table className="items-center w-full bg-transparent border-collapse">
                            <thead>
                                <tr key={"header"}>
                                    {headerKeys.map((key) => (
                                        <th
                                            key={key}
                                            className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blue-800 text-blue-300 border-blue-700"
                                        >
                                            {key}
                                        </th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>
                                {csvContent.map((item) => (
                                    <tr key={item.id}>
                                        {Object.values(item).map((val) => (
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {val}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="text-sm text-gray-100 my-3 px-6">
                        No .csv uploaded yet!
                    </div>
                )}
            </div>
        </div>
    );
};

export default Generate;
