import { FileUploader } from "react-drag-drop-files";
import { useMassMint } from "./context";
import { UploadIcon, CsvDocumentIcon } from "../../icons";

const Upload = ({ changeTab }) => {
    const {
        data: { imagesZipContent, csvContent, imgFilename, csvFilename },
        fn: { setImagesZipContent, setCsvContent, setImgFilename, setCsvFilename },
    } = useMassMint();

    const handleZipChange = (input) => {
        const reader = new FileReader();
        reader.onload = function () {
            setImagesZipContent(reader.result);
        };

        setImgFilename(input.name);
        reader.readAsArrayBuffer(input);
    };

    const handleCsvChange = (input) => {
        const reader = new FileReader();
        reader.onload = function () {
            let json = csvFileToArray(reader.result)
            setCsvContent(json);
        };

        setCsvFilename(input.name)
        reader.readAsText(input);
    };

    const csvFileToArray = (str) => {
        const csvHeader = str.slice(0, str.indexOf("\n")).split(",");
        const csvRows = str.slice(str.indexOf("\n") + 1).split("\n");

        const arr = csvRows.map((i) => {
            const values = i.split(",");
            const obj = csvHeader.reduce((object, header, index) => {
                object[header.trim()] = values[index].trim();
                return object;
            }, {});
            return obj;
        });

        return arr;
    };


    return (
        <>
            <FileUploader
                value={imagesZipContent}
                handleChange={handleZipChange}
                name="uploadZip"
                multiple={false}
                types={["ZIP"]}
            >
                <div className="cursor-pointer mt-1">
                    <div className="upload-field text-gray-300 py-10 h-48 flex flex-col items-center justify-center text-center rounded-md">
                    {imagesZipContent ? (
                        <div className="flex flex-col">
                            <div className="mb-3 text-lg">{imgFilename}</div>
                        </div>
                    ) : (
                        <>
                            <UploadIcon />
                            <p className="text-md font-semibold pt-3">
                                Upload or drop your images .zip right here
                            </p>
                        </>
                    )}
                    </div>
                </div>
            </FileUploader>
            <div className="my-4">{" "}</div>
            <FileUploader
                value={csvContent}
                handleChange={handleCsvChange}
                name="uploadCsv"
                multiple={false}
                types={["CSV"]}
            >
                <div className="cursor-pointer mt-1">
                    <div className="upload-field text-gray-300 py-10 h-48 flex flex-col items-center justify-center text-center rounded-md">
                    {csvContent ? (
                        <div className="flex flex-col">
                            <div className="mb-3 text-lg">{csvFilename}</div>
                        </div>
                    ) : (
                        <>
                            <CsvDocumentIcon />
                            <p className="text-md font-semibold pt-3">
                                Upload or drop your .csv file right here
                            </p>
                        </>
                    )}
                    </div>
                </div>
            </FileUploader>
        </>
    );
};

export default Upload;