import { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FileUploader } from "react-drag-drop-files";
import { formFieldStyle, strokeButtonStyle } from "../../utils";
import { ImageIcon } from "../../icons";
import { useGenerativeMint } from "./context";

const Settings = () => {
    //const [image, setImage] = useState(null);
    const {
        data: { collectionData, coverImage, maxCombinations },
        fn: { setCollectionData, setCoverImage, setFormErrors, validateFormInfo }
    } = useGenerativeMint() ;

    const handleSubmit = () => {}
    
    const handleValidate = (values) => {
        const errors = validateFormInfo(values);

        setFormErrors(Object.keys(errors).length !== 0)
        setCollectionData(values)
        return errors;
    };

    const handleImageChange = (image) => {
        const reader = new FileReader();
        reader.onload = function () {
            //setImage(reader.result);
            setCoverImage(reader.result)
        };
        reader.readAsDataURL(image);
    };

    const Error = ({ children }) => (
        <span className="text-xs text-red-400 ml-2">({children})</span>
    );

    const ImageInput = () => (
        <FileUploader
            value={coverImage}
            handleChange={handleImageChange}
            name="image"
            multiple={false}
            types={["JPG", "JPEG", "PNG", "GIF"]}
        >
            <div className="cursor-pointer mt-1">
                {coverImage ? (
                    <div className="img-prev" style={{backgroundImage: `url(${coverImage})`, height: "210px"}}></div>
                ) : (
                    <div 
                        className="upload-field py-5 px-2 flex flex-col items-center justify-center text-center rounded-md"
                        style={{height: "210px"}}
                    >
                        <ImageIcon />
                        <p className="text-sm font-semibold text-gray-300">
                            Upload or drop a cover image here
                        </p>
                    </div>
                )}
            </div>
        </FileUploader>
    )

    return (
        <div className="w-full text-gray-100 p-0 my-2">
            <Formik
            
                onSubmit={handleSubmit}
                validate={handleValidate}
                initialValues={collectionData}
            >
                <Form>
                    <div className="mb-3">
                        <div className="flex">
                            <div className="w-2/3">
                                <label className="text-sm">Number Of Tokens</label>
                                <ErrorMessage
                                    name="tokensQty"
                                    render={(msg) => <Error>{msg}</Error>}
                                />
                                <Field
                                    className={`${formFieldStyle} mb-1`}
                                    name="tokensQty"
                                    type="number"
                                    placeholder={`Max tokens: ${maxCombinations}`}
                                />
                                
                                <label className="text-sm">Symbol</label>
                                <ErrorMessage
                                    name="symbol"
                                    render={(msg) => <Error>{msg}</Error>}
                                />
                                <Field
                                    className={`${formFieldStyle} mb-1`}
                                    name="symbol"
                                    type="text"
                                    placeholder="Max: 4 chars"
                                />

                                <label className="text-sm">Name</label>
                                <ErrorMessage
                                    name="name"
                                    render={(msg) => <Error>{msg}</Error>}
                                />
                                <Field
                                    className={`${formFieldStyle} mb-1`}
                                    name="name"
                                    type="text"
                                    placeholder="Max: 64 chars"
                                />
                                
                                <label className="text-sm">Description</label>
                                <Field
                                    className={formFieldStyle}
                                    name="description"
                                    as="textarea"
                                    rows={3}
                                    placeholder="Max: 256 chars"
                                />
                            </div>
                            <div className="w-1/3 h-32 p-2">
                                <ImageInput />
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default Settings;