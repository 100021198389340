import { useGenerativeMint } from "./context";
import LayerCard from "./LayerCard";

const Manage = () => {
    const {
        data: { layersContent, layersImages },
        fn: { handleEditName, handleEditWeight },
    } = useGenerativeMint();

    let cards = [];
    layersContent.forEach(({ name, options }, i) => {
        const arr = options.map((o) => ({ layer: name, layerIndex: i, ...o }));
        cards = [...cards, ...arr];
    });

    if (layersContent.length === 0) {
        return (
            <div className="text-sm text-gray-100 my-3">No zip uploaded!</div>
        );
    }

    return (
        <div className="flex flex-wrap -mx-4">
            {cards.map((card) => (
                <LayerCard
                    key={card.file}
                    data={card}
                    image={layersImages[card.file]}
                    onEditName={handleEditName}
                    onEditWeight={handleEditWeight}
                />
            ))}
        </div>
    );
};

export default Manage;
