import { useState } from "react";
import { Rating } from "@smastrom/react-rating";
import { indexToStyle } from "../../utils";
import TextEditable from "./TextEditable";

const CustomStar = (
    <path d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z" />
);

const myStyles = {
    itemShapes: CustomStar,
    itemStrokeWidth: 1,
    activeFillColor: "rgb(2 132 199)",
    activeStrokeColor: "rgb(224 242 254)",
    inactiveFillColor: "rgb(224 242 254)",
    inactiveStrokeColor: "rgb(2 132 199)",
};

const LayerCard = ({
    image,
    data: { name, file, weight, layer, layerIndex },
    onEditName,
    onEditWeight,
}) => {
    const [rating, setRating] = useState(weight * 5);

    const handleChangeName = (text) => {
        onEditName(text, layer, file);
    };

    const handleChangeWeight = (val) => {
        onEditWeight(val / 5, layer, file); // adjust weight to be less than 1
        setRating(val);
    };

    if (!file) return <div className="hidden"></div>;

    return (
        <div className="w-full sm:w-1/2 md:w-1/3 xl:w-1/4 p-4">
            <div className="h-2 w-full">&nbsp;</div>
            <div className="c-card block bg-darkmode shadow-md hover:shadow-xl rounded-lg overflow-hidden">
                <div className="relative pb-48 overflow-hidden bg-gray-200">
                    <img
                        className="absolute inset-0 h-full w-full object-contain"
                        src={image}
                        alt=""
                    />
                </div>
                <div className="p-4">
                    <h2 className="my-1 text-white font-bold">
                        <TextEditable
                            value={name}
                            onChangeText={handleChangeName}
                        />
                    </h2>
                    <h3 className="my-1 text-gray-400 font-bold flex justify-between items-center">
                        <Rating
                            style={{ maxWidth: 120 }}
                            value={rating}
                            onChange={handleChangeWeight}
                            itemStyles={myStyles}
                        />
                        <span
                            className={`inline-block px-2 py-1 leading-none rounded-md font-semibold uppercase tracking-wide text-xs ${indexToStyle(
                                layerIndex
                            )}`}
                        >
                            {layer}
                        </span>
                    </h3>
                </div>
            </div>
        </div>
    );
};

export default LayerCard;
