{
  "name": "@unique-nft/sdk",
  "version": "0.2.16",
  "type": "module",
  "homepage": "https://unique.network/",
  "repository": {
    "type": "git",
    "url": "https://github.com/UniqueNetwork/unique-sdk.git"
  },
  "dependencies": {
    "abi-coder": "^4.1.1",
    "axios": "^0.27.2",
    "form-data": "^4.0.0",
    "socket.io-client": "^4.5.4"
  },
  "description": "The @unique-nft/sdk package implements the SDK methods via the REST API. The package implements all the basic methods for working with the Unique Network SDK.",
  "keywords": [
    "blockchain",
    "parachain",
    "polkadot",
    "unique network",
    "nft"
  ],
  "bugs": {
    "url": "https://github.com/UniqueNetwork/unique-sdk/issues"
  },
  "license": "MIT",
  "author": {
    "name": "Unique Network SDK team"
  },
  "types": "index.d.ts",
  "main": "index.js",
  "module": "index.mjs",
  "exports": {
    ".": {
      "types": "./index.d.ts",
      "require": "./index.cjs",
      "default": "./index.js"
    },
    "./evm": {
      "types": "./evm.d.ts",
      "require": "./evm.cjs",
      "default": "./evm.js"
    },
    "./subscriptions": {
      "types": "./subscriptions.d.ts",
      "require": "./subscriptions.cjs",
      "default": "./subscriptions.js"
    },
    "./full": {
      "types": "./full.d.ts",
      "require": "./full.cjs",
      "default": "./full.js"
    }
  }
}
