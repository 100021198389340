import { useState } from "react";
import uuid from "react-uuid";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { formFieldStyle, pseudoRandId } from "../utils";

function TrashButton({ onClick }) {
    return (
        <button onClick={onClick} className="mr-2">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#ef5350"
                className="w-4 h-4"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
            </svg>
        </button>
    );
}

function AttributesTable({ attrs, setAttrs }) {
    const handleRmv = (id) => {
        const _attrs = attrs.filter((oid) => oid !== id);
        setAttrs(_attrs);
    };

    const handleAdd = () => {
        const id = pseudoRandId();
        setAttrs([...attrs, id])
    }

    const DivWrap = ({children}) => (
        <div className="w-full my-4 xl:mb-0 mx-auto">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
                { children }
            </div>
        </div>
    )

    return (
        <DivWrap>
            <div className="rounded-t mb-0 py-3 border-0">
                <div className="flex flex-wrap items-center">
                    <div className="relative w-full max-w-full flex-grow flex-1">
                        <h3 className="text-gray-100 font-bold">
                            Attributes
                        </h3>
                    </div>
                    <div className="relative w-full max-w-full flex-grow flex-1 text-right">
                        <button
                            className="bg-gray-500 text-white active:bg-gray-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => handleAdd()}
                        >
                            Add Attribute
                        </button>
                    </div>
                </div>
            </div>

            <div className="block w-full overflow-x-auto pb-4">
                <table className="items-center bg-transparent w-full border-collapse ">
                    <thead>
                        <tr>
                            <th className="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                Name
                            </th>
                            <th className="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                Type
                            </th>
                            <th className="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                                Default Values
                            </th>
                            <th className="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                Required
                            </th>
                            <th className="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                Mutable
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            attrs.length === 0 && (
                                <tr>
                                    <td colSpan={5} className="text-center text-xs text-gray-400 pt-3">No attributes added yet</td>
                                </tr>
                            )
                        }
                        {attrs.map(
                            (id) => (
                                <tr key={id}>
                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 pr-8 py-1 text-left text-blueGray-700 ">
                                        <TrashButton
                                            onClick={() => handleRmv(id)}
                                        />{" "}
                                        <Field
                                            className={formFieldStyle}
                                            name={`name_${id}`}
                                            type="text"
                                            placeholder="Name"
                                        />
                                    </td>
                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 py-1 text-left text-blueGray-700">
                                        <Field
                                            name={`type_${id}`}
                                            as="select"
                                            className={formFieldStyle}
                                            
                                        >
                                            <option value="STRING">
                                                TEXT
                                            </option>
                                            <option value="SELECT">
                                                SELECT
                                            </option>
                                            <option value="MULTISELECT">
                                                MULTISELECT
                                            </option>
                                        </Field>
                                    </td>
                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 py-1 text-left text-blueGray-700 ">
                                        <Field
                                            className={formFieldStyle}
                                            name={`values_${id}`}
                                            type="text"
                                            placeholder="Values"
                                        />
                                    </td>
                                    <td className="border-t-0 align-center border-l-0 border-r-0 text-xs whitespace-nowrap py-1 text-center">
                                        <Field
                                            type="checkbox"
                                            name={`required_${id}`}
                                        />
                                    </td>
                                    <td className="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap py-1 text-center">
                                        <Field
                                            type="checkbox"
                                            name={`mutable_${id}`}
                                        />
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>
        </DivWrap>
    );
}

export default AttributesTable;