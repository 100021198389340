import { FileUploader } from "react-drag-drop-files";
import { useGenerativeMint } from "./context";
import { UploadIcon } from "../../icons";
import Button from "../../common/Button";

const Upload = ({ changeTab }) => {
    const {
        data: { file, filename },
        fn: { handleFileChange, handleReadZip }
    } = useGenerativeMint();

    const handlePreviewLayers = () => {
        handleReadZip();
        setTimeout(() => {
            changeTab(1)
        }, 100);
    }

    return (
        <>
            <FileUploader
                value={file}
                handleChange={handleFileChange}
                name="upload"
                multiple={false}
                types={["ZIP"]}
            >
                <div className="cursor-pointer mt-1">
                    <div className="upload-field text-gray-300 py-10 h-48 flex flex-col items-center justify-center text-center rounded-md">
                    {file ? (
                        <div className="flex flex-col">
                            <div className="mb-3">{filename}</div>
                        </div>
                    ) : (
                        <>
                            <UploadIcon />
                            <p className="text-md font-semibold pt-3">
                                Upload or drop your .zip file right here
                            </p>
                        </>
                    )}
                    </div>
                </div>
            </FileUploader>
            { !filename ? null : (
                <div className="w-full flex justify-center my-3">
                    <Button onClick={() => handlePreviewLayers()}>Preview Layers</Button>
                </div>
            )}
        </>
    )
}

export default Upload;