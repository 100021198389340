import _ from "lodash";
import { useEffect, useState } from "react";
import { useGenerativeMint } from "./context";
import { generateNFTImages, generateNFTs, downloadZip } from "./generator";
import Button from "../../common/Button";

const Generate = () => {
    const {
        data: { layersContent, layersImages, simulatedResult, collectionData, formErrors },
        fn: { setSimulatedResult, mintCollection },
    } = useGenerativeMint();

    const [statistics, setStatistics] = useState([]);

    useEffect(() => {
        if (simulatedResult.lenght !== 0)
            getStatistics(simulatedResult)
    }, [])

    const handleMintCollection = async () => {
        if (simulatedResult.lenght === 0) {
            alert("Please generate tokens first!");
            return;
        }

        if (formErrors) {
            alert("There's missing collection data in Settings form!");
            return;
        }

        const zipImages = await generateNFTImages(simulatedResult, layersImages);
        mintCollection(zipImages);
    }

    const handleDownloadCollection = async () => {
        if (simulatedResult.lenght === 0) return;

        const zipImages = await generateNFTImages(simulatedResult, layersImages);
        downloadZip(zipImages);
    }

    const handleSimulation = () => {
        if (!collectionData.tokensQty) {
            alert("Missing 'Number Of Tokens' in Settings form!");
            return;
        }

        const result = generateNFTs(collectionData.tokensQty, layersContent)
        setSimulatedResult(result)
        getStatistics(result)
        //console.log(result)
    }

    const getStatistics = (simulation) => {
        const layers = layersContent.map(o => o.name)
        const _statistics = layers.map(layer => {
            const paths = simulation.map(o => o[layer].f)
            return _.countBy(paths);
        })

        setStatistics(_statistics)
    }

    const getPercent = (num) => {
        const qty = simulatedResult.length; //collectionData.tokensQty;
        const percent = (num*100/qty).toFixed(1);
        return `${percent}%`;
    }

    const getAttrName = (layerName, filePath) => {
        const layer = layersContent.find(o => o.name === layerName);
        const item = layer.options.find(o => o.file === filePath);
        return item.name;
    }

    const handleTest = async () => {
        console.log("do something")
    }

    return (
        <div className="text-white my-3">
            <Button onClick={() => handleSimulation()}>Generate Tokens</Button>{" "}
            <Button onClick={() => handleDownloadCollection()}>Download Images</Button>{" "}
            <Button onClick={() => handleMintCollection()}>Mint Collection</Button>
            {/* <Button onClick={() => handleTest()}>Test Something</Button> */}

            <div className="my-3">
                {
                    statistics.map((items, index) => (
                        <div key={index}>
                            <div className="bg-darkdeep font-semibold p-2">
                                {layersContent[index].name}
                            </div>
                            <div className="flex flex-wrap py-3">
                            {Object.keys(items).map(filePath => (
                                <div key={filePath} className="flex text-sm p-1 px-2">
                                    <div className="w-16 bg-gray-100 rounded-l-md">
                                        <img className="w-full" src={layersImages[filePath]} alt=""/>
                                    </div>
                                    <div className="flex flex-col justify-center px-2 border border-1 rounded-r-md">
                                        <div>{getAttrName(layersContent[index].name, filePath)}</div>
                                        <div>({items[filePath]}) {getPercent(items[filePath])}</div>
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Generate;