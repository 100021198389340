const weatherKey = process.env.REACT_APP_API_KEY_WEATHER;
const newsKey = process.env.REACT_APP_API_KEY_NEWS;

export const sources = {
    news: {
        lisbon: `https://newsapi.org/v2/top-headlines?country=pt&apiKey=${newsKey}&pageSize=5`,
        london: `https://newsapi.org/v2/top-headlines?country=gb&apiKey=${newsKey}&pageSize=5`,
        paris: `https://newsapi.org/v2/top-headlines?country=fr&apiKey=${newsKey}&pageSize=5`,
        ny: `https://newsapi.org/v2/top-headlines?country=us&apiKey=${newsKey}&pageSize=5`
    },
    weather: {
        lisbon: `https://api.openweathermap.org/data/2.5/weather?lat=38.73&lon=-9.14&units=metric&appid=${weatherKey}`,
        london: `https://api.openweathermap.org/data/2.5/weather?lat=51.50&lon=-0.13&units=metric&appid=${weatherKey}`,
        paris: `https://api.openweathermap.org/data/2.5/weather?lat=48.86&lon=2.34&units=metric&appid=${weatherKey}`,
        ny: `https://api.openweathermap.org/data/2.5/weather?lat=43.00&lon=-75.00&units=metric&appid=${weatherKey}`,
    },
    prices: "https://api.dydx.exchange/v3/markets"
}

// export const sources = {
//     news: {
//         lisbon: "/dynamic/mockapi/news.json",
//         london: "/dynamic/mockapi/news.json",
//         paris: "/dynamic/mockapi/news.json",
//         ny: "/dynamic/mockapi/news.json"
//     },
//     weather: {
//         lisbon: "/dynamic/mockapi/weather.json",
//         london: "/dynamic/mockapi/weather.json",
//         paris: "/dynamic/mockapi/weather.json",
//         ny: "/dynamic/mockapi/weather.json",
//     },
//     prices: "/dynamic/mockapi/prices.json"
// }

const avatar = {
    "rain": "/dynamic/avatar/rain.txt",
    "bear": "/dynamic/avatar/bear.txt",
    "clouds": "/dynamic/avatar/clouds.txt",
    "drizzle": "/dynamic/avatar/drizzle.txt",
    "bull": "/dynamic/avatar/bull.txt",
    "snow": "/dynamic/avatar/snow.txt",
    "clear": "/dynamic/avatar/clear.txt",
    "thunderstorm": "/dynamic/avatar/thunderstorm.txt",
    "clear-01n": "/dynamic/avatar/clear-01n.txt",
    "clear-01d": "/dynamic/avatar/clear-01d.txt"  
}

export const mutations = {
    background: {
        news: "/dynamic/bg/bubble.svg",
        price: { bull: "/dynamic/bg/fireworks.svg", bear: "/dynamic/bg/matrix.svg" },
        weather: { clear: "/dynamic/bg/cloud.svg", rain: "/dynamic/bg/ripple.svg" }
    },
    header: {
        news: "#news#",
        price: (a, pr, pe) => `Price of ${a} goes to ${pr} USD with a variation of ${pe} the last 24h`,
        weather: (c, s, n) => `The current weather in ${c} is ${s} and the temperature is ${n}°C`
    },
    leftcol: {
        top: {
            price: { bull: (a) => `${a} moves up`, bear:(a) => `${a} moves down` },
            weather: "#status#",
            news: "Business News"
        },
        down: {
            price: { bull: "Happy Times", bear: "Investors Panic" },
            weather: "Prepare Yourself",
            news: "Banks are Old"
        },
        image: {
            price: { bull: avatar.bull, bear: avatar.bear },
            weather: (status) => avatar[status],
            news: avatar.bear
        }
    },
    rightcol: {
        icon: {
            price: { bull: "/dynamic/weather/bull.svg", bear: "/dynamic/weather/bear.svg" },
            weather: (icon) => `/dynamic/weather/${icon}.svg`,
            news: "/dynamic/weather/clear-01d.svg"
        },
        status: {
            price: { bull: "Up and Shiny", bear: "Down and Cloudy" },
            weather: "#status#",
            news: "Never stop Learning"
        }
    }
}

export const heroImage = {
    lisbon: "/dynamic/header/lisbon.txt",
    london: "/dynamic/header/london.txt",
    paris: "/dynamic/header/paris.txt",
    ny: "/dynamic/header/ny.txt",
} 

const baseBG = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;background:#71c2cc;display:block;z-index:1;position:relative" width="800" height="800" preserveAspectRatio="xMidYMid" viewBox="0 0 800 800"><!--#####--></svg>'
export const baseNewspaper = {
    background: baseBG,
    headerTitle: "Seven AI companies agree to safeguards in the US - BBC",
    headerImage: "https://bafybeidlvda3uhuaqkf44abcsgsxjfohwivuoihnonscwuzietximpstge.ipfs.w3s.link/file.jpg", 
    weatherTemp : "",
    weatherIcon: "",
    weatherTitle: "Weather in New York",
    weatherStatus: "",
    avatarImage: "",
    avatarTitle: "Business News",
    avatarSubtitle: "Bull times now"
}