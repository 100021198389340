import { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import GenerativeMintProvider from './context';
import Layout from "../../layout";
import Upload from './Upload';
import Layers from "./Layers";
import Manage from "./Manage";
import Settings from "./Settings";
import Generate from "./Generate";

const GenerativeMint = () => {
    const [tabIndex, setTabIndex] = useState(0);

    const tabStyle = "cursor-pointer inline-block border border-2 border-gray-100 text-gray-100 rounded-lg px-5 py-1 mr-2 outline-none my-3";
    const tabStyleSel = "!border-blue-500 !text-blue-500 bg-gray-100/5";

    return (
        <GenerativeMintProvider>
            <Layout>
                <h3 className="text-gray-100 font-bold text-xl mt-4 mb-4">Generative Mint</h3>
                <h5 className="text-sm text-gray-300">Generates a new collection with random NFTs using a set of image layers</h5>

                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <TabList>
                        <Tab className={tabStyle} selectedClassName={tabStyleSel}>Upload</Tab>
                        <Tab className={tabStyle} selectedClassName={tabStyleSel}>Layers</Tab>
                        <Tab className={tabStyle} selectedClassName={tabStyleSel}>Manage</Tab>
                        <Tab className={tabStyle} selectedClassName={tabStyleSel}>Settings</Tab>
                        <Tab className={tabStyle} selectedClassName={tabStyleSel}>Generate</Tab>
                    </TabList>

                    <TabPanel>
                        <Upload changeTab={setTabIndex} />
                    </TabPanel>
                    <TabPanel>
                        <Layers />
                    </TabPanel>
                    <TabPanel>
                        <Manage />
                    </TabPanel>
                    <TabPanel>
                        <Settings />
                    </TabPanel>
                    <TabPanel>
                        <Generate />
                    </TabPanel>
                </Tabs>
            </Layout>
        </GenerativeMintProvider>
    )

}

export default GenerativeMint;