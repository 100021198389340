import {
  SubmitTxBody,
  UnsignedTxPayloadResponse,
  Account,
  Options,
  Signer,
  WithAddress,
  WithOptionalAddress,
} from './types';

export const isUnsignedTxPayloadResponse = (
  args: unknown,
): args is UnsignedTxPayloadResponse =>
  typeof args === 'object' && !!args && 'signerPayloadJSON' in args;

export const isSubmitTxBody = (args: unknown): args is SubmitTxBody =>
  isUnsignedTxPayloadResponse(args) && !!args && 'signature' in args;

export function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const hasOwnProperty = (obj: unknown, prop: string): boolean =>
  Object.prototype.hasOwnProperty.call(obj, prop);

export const getSigner = (
  options: Options,
  accountOrSigner?: Account | Signer,
): Signer | undefined => {
  if (accountOrSigner) {
    return hasOwnProperty(accountOrSigner, 'sign')
      ? (accountOrSigner as Signer)
      : (accountOrSigner as Account).signer;
  }

  if (options.signer) return options.signer;
  if (options.account?.signer) return options.account.signer;

  return undefined;
};

export const getAddress = (
  options: Options,
  args: { address?: string },
): string | undefined => {
  if (args.address) return args.address;
  if (options.signer?.address) return options.signer.address;
  if (options.account?.address) return options.account.address;
  if (options.account?.signer?.address) return options.account.signer.address;

  return undefined;
};

export const addAddress = <A extends WithOptionalAddress>(
  options: Options,
  args: A,
): A & WithAddress => {
  const address = getAddress(options, args);
  if (!address) throw new Error('No address provided');

  return { ...args, address };
};
