import React, { useState } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// fake data generator
const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map((k) => ({
        id: `item-${k + offset}-${new Date().getTime()}`,
        content: `item ${k + offset}`,
    }));

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
	fontSize: "0.8em",
	fontWeight: "500",
    userSelect: "none",
    padding: grid,
    margin: `0 0 ${grid}px 0`,
    border: "solid 3px #0a1635",
    borderRadius: "5px",

    // change background colour if dragging
    background: isDragging ? "#FF9800" : "#334155",

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "aliceblue" : "lightgrey",
	padding: 8,
    marginBottom: 4,
	
	minHeight: 30,
});

const DndItem = ({ item, index }) => (
    <Draggable key={item.id} draggableId={item.id} index={index}>
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                )}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                    }}
                >
                    {item.content}
                </div>
            </div>
        )}
    </Draggable>
);

const DndPanel = ({el, ind}) => {
	const titles = panelsData.map(o => o.title);
	return (
		<div>
            <div className="text-xs text-center font-bold text-gray-700 bg-gray-300 pt-2">
                {titles[ind]}
            </div>
            <Droppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={getListStyle(
                            snapshot.isDraggingOver
                        )}
                        {...provided.droppableProps}
                    >
                        
                        {el.map((item, index) => (
                            <DndItem item={item} index={index} key={item.id ? item.id : index} />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
		</div>
	)
};

const sources = [
    { id: "news", content: "News" },
    { id: "weather", content: "Weather" },
    { id: "btc", content: "BTC Price" },
    { id: "eth", content: "ETH Price" },
    { id: "dot", content: "DOT Price" },
];

const panelsData = [
	{ title: "sources", data: sources },
	{ title: "background", data: [] },
	{ title: "header", data: [] },
	{ title: "leftCol", data: [] },
	{ title: "rightCol", data: [] },
]

function SourceMutators({ onChange }) {
    const [state, setState] = useState(panelsData.map(o => o.data));

    function onDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            const items = reorder(state[sInd], source.index, destination.index);
            const newState = [...state];
            newState[sInd] = items;
            setState(newState);
        } else {
            const result = move(state[sInd], state[dInd], source, destination);
            const newState = [...state];
            newState[sInd] = result[sInd];
            newState[dInd] = result[dInd];

            //setState(newState.filter(group => group.length)); // this removes empty boards
            setState(newState);
            onChange(panelsData.map(o => o.title), newState);
        }
    }

	const sourcesPanel = state[0];
	const mutatorPanels = state.filter((o,i) => i !== 0);

    return (
		<DragDropContext onDragEnd={onDragEnd}>
			<div className="h-96 flex flex-col sm:flex-row font-sans">
				<div className="w-full lg:w-1/2 overflow-auto px-1">
					<DndPanel el={sourcesPanel} ind={0} />
				</div>
				<div className="w-full lg:w-1/2 overflow-auto px-1">
					{mutatorPanels.map((el, ind) => (
						<DndPanel key={ind+1} el={el} ind={ind+1} />
					))}
				</div>
			</div>
		</DragDropContext>
    );
}

export default SourceMutators;
