import { useEffect, useState } from 'react';
import { Flipper, Flipped } from 'react-flip-toolkit';
import { useGenerativeMint } from "./context";
import mergeImages from 'merge-images';

const transparentPixel = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=";

const Layers = () => {
    const [preview, setPreview] = useState(transparentPixel);
    const {
        data: { layersContent, layersExample },
        fn: { reorderLayers }
    } = useGenerativeMint();
    
    useEffect(() => {
        updatePreview();
    }, [layersContent])

    const updatePreview = async () => {
        if (layersExample.length === 0) return;
        
        const arrayCopy = [...layersExample];
        arrayCopy.reverse();
        const base64Image = await mergeImages(arrayCopy);
        setPreview(base64Image);
    }

    const moveItem = (array, from, to) => {
        const result = [...array];
        var f = result.splice(from, 1)[0];
        result.splice(to, 0, f);
        return result;
    }

    const layerUp = (name) => {
        const index = layersContent.findIndex(o => o.name === name);
        if (index === 0) return;
        const updateLC = moveItem(layersContent, index, index - 1);
        const updateLE = moveItem(layersExample, index, index - 1);
        reorderLayers(updateLC, updateLE);
    }

    const layerDown = (name) => {
        const index = layersContent.findIndex(o => o.name === name);
        if (index === layersContent.length - 1) return;
        const updateLC = moveItem(layersContent, index, index + 1);
        const updateLE = moveItem(layersExample, index, index + 1);
        reorderLayers(updateLC, updateLE);
    }

    const removeLayer = (name) => {
        const index = layersContent.findIndex(o => o.name === name);
        const updateLC = layersContent.filter((o, i) => i !== index);
        const updateLE = layersExample.filter((o, i) => i !== index);
        reorderLayers(updateLC, updateLE);
    }

    if (layersContent.length === 0) {
        return <div className="text-sm text-gray-100 my-3">No zip uploaded!</div>
    }

    return (
        <>
            <div className="h-72 flex flex-col sm:flex-row font-sans">
                <div className="bg-gray-100 rounded-md w-full lg:w-2/4 flex justify-center">
                    <img src={preview} alt="layers preview" className="h-full"/>
                </div>
                <div className="w-full lg:w-2/4 pl-3">
                    <Flipper flipKey={layersContent.map(o => o.name).join('')}>
                        <div>
                            {layersContent.map(({name}) => (
                                <Flipped key={name} flipId={name} stagger>
                                    <div className="flex text-gray-100 text-md w-full border border-gray-600 bg-slate-700 rounded-sm mb-2">   
                                        <button className="bg-blue-400 px-3" onClick={() => layerUp(name)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-3 h-3">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                            </svg>
                                        </button>
                                        <button className="bg-yellow-600 px-3 mr-1" onClick={() => layerDown(name)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-3 h-3">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                            </svg>
                                        </button>
                                        <div className="flex flex-1 items-center justify-between">
                                            <span className="font-semibold">{name}</span>
                                            <button className="bg-red-400 p-3" onClick={() => removeLayer(name)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-3 h-3">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </Flipped>
                            ))}
                        </div>
                        </Flipper>
                </div>
            </div>
        </>
    )
}

export default Layers;