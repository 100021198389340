import { strokeButtonStyle } from "../utils";

const Button = ({ children, onClick, type = "button", selected = false }) => (
    <button
        type={type}
        className={`${strokeButtonStyle} ${selected ? "!text-gray-200 !bg-blue-700" : ""}`}
        onClick={onClick}
    >
        {children}
    </button>
)

export default Button;