import { useState } from "react";
import Modal from "../layout/Modal";
import SourcesLab from "./SourcesLab";

const ModalSynth = ({ collectionId, tokenId, isShow, setIsShow }) => {
	return (
		<Modal
			maxWidth="5xl"
            show={isShow}
            handleShow={setIsShow}
			activator={({ handleShow }) => (<div></div> )}
		>
			<div className="bg-slate-800 text-gray-100 pt-4 pb-8 px-8 rounded-md" style={{width: "960px", height: "600px"}}>
				<h4 className="text-lg mb-6 font-semibold">Synthetize Dynamic Token</h4>
                <SourcesLab collectionId={collectionId} tokenId={tokenId} />
			</div>
		</Modal>
	)
}

export default ModalSynth